import * as React from "react";

import Blob from '../Blob';
import blobMesh from '../Blob/meshes/blobMesh';

import useMediaQuery from '../../utils/useMediaQuery';
import s from './LogoBlob.module.scss';

export interface LogoBlobProps {
    componentName: string;
}

const LogoBlob: React.FC<LogoBlobProps> = ({ componentName }) => {
    const useSmallDeformation = useMediaQuery({
        query: `(max-width: 960px)`
    });

    const defaultColor = '#37f48d';

    const colors = {
        CaseListPage: defaultColor,
        PageArchive: '#ffe7de'
    };

    const deformationScale = useSmallDeformation
        ? [50, 50]
        : [30, 30];

    return (
        <div>
            <Blob
                color={colors[componentName] || defaultColor}
                mesh={blobMesh}
                scale={200}
                deformationScale={deformationScale}
                magnitude={0.015}
                speed={0.003}
                wrapperClassName={s.BlobWrapper}
            />
        </div>
    )
};

export default LogoBlob
