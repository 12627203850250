import * as React from 'react';
import NextLink from 'next/link';

import { Link as LinkProps } from '../../types/';

const Link: React.FC<LinkProps> = ({ href, target, className, children }) => {
    href = href || '';
    const isRelative = href.indexOf("http") !== 0;

    if (isRelative && !target) {
        return (
            <NextLink href={href}>
                <a className={className}>
                    {children}
                </a>
            </NextLink>
        )
    }

    return (
        <a href={href} target={target} className={className}>
            {children}
        </a>
    );
};

export default Link;
