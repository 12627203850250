import * as React from 'react';
import { Spring, Transition, config } from 'react-spring/renderprops.cjs';

import classNames from 'classnames';
import RawHtml from "../RawHtml";

import CircleBlob from '../CircleBlob';

import useMediaQuery from '../../utils/useMediaQuery';
import s from './ContactButtonModal.module.scss';

export interface ContactInfoProps {
    title: string,
    text: string,
    ctaTextLeft: string,
    ctaTextRight: string,
}

const ContactButtonModal: React.FC<ContactInfoProps> = (props: ContactInfoProps) => {
    const modalWrapperRef = React.useRef(null);

    const [isActive, setIsActive] = React.useState<boolean>(false);
    const [buttonHover, setButtonHover] = React.useState<boolean>(false);

    const buttonClasses = classNames(s.Button, { [s['Button--Active']]: isActive });
    const openButtonClasses = classNames(s.Button, { [s['Button--Hidden']]: isActive });

    const modalClasses = classNames(s.Modal, { [s['Modal--Active']]: isActive });

    const handleKeypress = (e: KeyboardEvent): void => {
        e.stopPropagation();

        if (e.keyCode === 27) {
            setIsActive(false);
        }
    }

    const useBigGrowth = useMediaQuery({
        query: `(min-width: 1280px)`
    });

    const useMediumGrowth = useMediaQuery({
        query: `(max-width: 1280px)`
    });

    const useSmallGrowth = useMediaQuery({
        query: `(max-width: 768px)`
    });

    // set focus & listen for esc key press on mount
    React.useEffect((): () => void => {
        document.addEventListener('keydown', handleKeypress, false);

        return ():void => {
            document.removeEventListener('keydown', handleKeypress, false);
        }
    }, [isActive]);

    return (
        <Spring
            config={{
                mass: 4.5,
                tension: 210,
                friction: 20,
                velocity: 16,
                clamp: true
            }}
            to={{
                scale: isActive
                    ? useBigGrowth
                        ? 220
                        : 250
                    : 0,
                top: isActive
                    ? useSmallGrowth
                        ? 200
                        : 130
                    : useSmallGrowth
                        ? -13
                        : useMediumGrowth
                            ? -6
                            : -5,
                left: isActive
                    ? useMediumGrowth
                        ? 150
                        : 120
                    : useSmallGrowth
                        ? -10
                        : useMediumGrowth
                            ? -3
                            : -3
            }}
        >
            {growAnimProps =>
                <>
                    <button
                        onClick={(e) => {
                            e.currentTarget.blur();
                            setIsActive(!isActive);
                        }}
                        onMouseEnter={() => setButtonHover(true)}
                        onMouseLeave={() => setButtonHover(false)}
                        aria-label="Öppna kontaktvy"
                        className={openButtonClasses}
                    >
                        {!isActive &&
                            <CircleBlob active={buttonHover} />
                        }

                        <div className={s.GrowBlobWrapper}>
                            <div
                                className={s.GrowBlob}
                                style={{
                                    width: `${growAnimProps.scale}vw`,
                                    height: `${growAnimProps.scale}vw`,
                                    top: `${-growAnimProps.top}vw`,
                                    left: `${-growAnimProps.left}vw`,
                                }}
                            />
                        </div>
                    </button>

                    <Transition
                        config={{
                            mass: 2.5,
                            tension: 210,
                            friction: 20,
                            velocity: 0,
                            clamp: true
                        }}
                        items={isActive}
                        delay={250}
                        from={{ opacity: 0 }}
                        enter={{ opacity: 1 }}
                        leave={{ opacity: 0 }}
                        onRest={() => {
                            if(modalWrapperRef.current) {
                                modalWrapperRef.current.focus();
                            }
                        }}
                    >
                        {show => show && ((fadeAnimProps) =>
                            <div
                                role="dialog"
                                tabIndex={-1}
                                className={modalClasses}
                                ref={modalWrapperRef}
                                style={fadeAnimProps}
                            >
                                <div className={s.LogoContainer}>
                                    <button className={s.Logo} onClick={() => setIsActive(!isActive)}/>
                                </div>
                                <button
                                    onClick={() => setIsActive(!isActive)}
                                    onMouseEnter={() => setButtonHover(true)}
                                    onMouseLeave={() => setButtonHover(false)}
                                    className={buttonClasses}
                                >
                                </button>
                                <Transition
                                    config={{
                                        mass: 0.1,
                                        tension: 368,
                                        friction: 20,
                                        velocity: 16
                                    }}
                                    delay={350}
                                    items={isActive}
                                    from={{ offset: 20, opacity: 0 }}
                                    enter={{ offset: 0, opacity: 1 }}
                                    leave={{ offset: 20, opacity: 0 }}
                                >
                                    {show => show && ((translateAnimProps) => (
                                        <div
                                            style={{
                                                transform: `translateY(-${translateAnimProps.offset}px)`,
                                                opacity: translateAnimProps.opacity
                                            }}
                                            className={s.ModalContainer}
                                        >
                                            <div className={s.Row}>
                                                <div className={s.Column}>
                                                    <h1 className={s.Title}>
                                                        {props.title}
                                                    </h1>
                                                </div>
                                                <div className={s.Column}>
                                                    <RawHtml html={props.text} />
                                                </div>
                                            </div>
                                            <div className={s.Row}>
                                                <div className={s.Column}>
                                                    <div className={`${s.Cta} ${s.CtaLeft}`}>
                                                        <RawHtml html={props.ctaTextLeft} />
                                                    </div>
                                                </div>
                                                <div className={s.Column}>
                                                    <div className={`${s.Cta} ${s.CtaRight}`}>
                                                        <RawHtml html={props.ctaTextRight} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Transition>
                            </div>
                        )}
                    </Transition>
                </>
            }
        </Spring>
    );
};

export default ContactButtonModal;
