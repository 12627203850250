const hexToNormedRgb = (h: string): number[] => {
    let r: string, g: string, b: string;

    if (h.length === 4) {
        r = '0x' + h[1] + h[1];
        g = '0x' + h[2] + h[2];
        b = '0x' + h[3] + h[3];
    } else if (h.length === 7) {
        r = '0x' + h[1] + h[2];
        g = '0x' + h[3] + h[4];
        b = '0x' + h[5] + h[6];
    }

    let nr = Number(r) / 255;
    let ng = Number(g) / 255;
    let nb = Number(b) / 255;

    return [nr, ng, nb];
};

const degToRad = (theta: number): number =>
    theta * (Math.PI / 180);

export {
    hexToNormedRgb,
    degToRad
};
