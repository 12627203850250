import * as React from "react";
import { Spring, config } from 'react-spring/renderprops.cjs';

import { mat4 } from 'gl-matrix';

import classNames from 'classnames';

import Blob from '../Blob';
import circleMesh from '../Blob/meshes/circleMesh';

import { degToRad } from '../../utils/graphics';

import useMediaQuery from '../../utils/useMediaQuery';
import s from './CircleBlob.module.scss';

export interface CircleBlobProps {
    active: boolean;
}

const CircleBlob: React.FC<CircleBlobProps> = ({ active }) => {
    let _ = mat4.create();

    const rotationGreen = mat4.rotateX(_,
        mat4.create(),
        degToRad(90)
    );

    let circleDims = {
        width: 84,
        height: 84
    };

    const alwaysActive = useMediaQuery({
        query: `(max-width: 768px)`
    });

    const useMediumCircle = useMediaQuery({
        query: `(min-width: 768px)`
    });

    const useLargeCircle = useMediaQuery({
        query: `(min-width: 1280px)`
    });

    if(useMediumCircle) {
        circleDims = {
            width: 100,
            height: 100
        };
    }

    if(useLargeCircle) {
        circleDims = {
            width: 130,
            height: 130
        }
    }

    const blobClasses = classNames(
        s.BlobWrapper, {
            [s.BlobWrapperActive]: active || alwaysActive
        }
    );

    return (
        <div className={s.Root}>
            <Spring
                config={config.wobbly}
                to={{ magnitude: active || alwaysActive ? 0.25 : 0 }}
            >
                {animProps =>
                    <Blob
                        color='#250154'
                        mesh={circleMesh}
                        scale={10}
                        deformationScale={[1.0, 1.0]}
                        magnitude={animProps.magnitude}
                        speed={!alwaysActive ? 0.02 : 0.01}
                        rotation={rotationGreen}
                        wrapperClassName={blobClasses}
                        {...circleDims}
                    />
                }
            </Spring>
        </div>
    )
};

export default CircleBlob
