import * as React from "react";
import classNames from "classnames";

import s from "./Footer.module.scss";

export interface FooterProps {
    contactTitle: string;
    address: string;
    siteSetting: any;
}

const Footer: React.FC<FooterProps> = ({
    contactTitle,
    address,
    siteSetting,
}) => {
    return (
        <div className={s.Root} id="Footer">
            <div className={s.Top}>
                <p className={s.Label}>Kontakta oss</p>
                <div
                    className={`${s.Wysiwyg} ${s.WysiwygTop}`}
                    dangerouslySetInnerHTML={{ __html: contactTitle }}
                />
            </div>
            <div className={s.Bottom}>
                <p className={s.Label}>Här sitter vi</p>
                <div
                    className={`${s.Wysiwyg} ${s.WysiwygBottom}`}
                    dangerouslySetInnerHTML={{ __html: address }}
                />
                <button
                    className={classNames(s.Button, "cookie-consent-settings")}
                >
                    Cookieinställningar
                </button>
            </div>
        </div>
    );
};

export default Footer;
