
import * as React from "react";

import reglInit, { Regl } from 'regl';
import { mat4 } from 'gl-matrix';

import { hexToNormedRgb } from '../../utils/graphics';

import s from '../Blob/Blob.module.scss';

export interface BlobProps {
    color?: string;
    mesh: any;
    scale: number;
    speed: number;
    rotation?: mat4;
    deformationScale: number[];
    magnitude: number;
    wrapperClassName: string;
    width?: number;
    height?: number;
}

const Blob: React.FunctionComponent<BlobProps> = (
    props: BlobProps
) => {
    const {
        color,
        mesh,
        rotation,
        scale,
        speed,
        deformationScale,
        magnitude,
        wrapperClassName,
        width,
        height
    } = props;

    const canvasRef = React.useRef(null);
    const blobColor = hexToNormedRgb(color);

    let seed:React.MutableRefObject<number> = React.useRef(null);
    let reglInstance:React.MutableRefObject<Regl> = React.useRef(null);

    React.useEffect(() => {
        seed.current = 1000 + Math.random() * 1000;

        let gl = canvasRef.current.getContext('webgl', {
            alpha: true,
            antialias: true,
            stencil: false,
            preserveDrawingBuffer: false,
            depth: false
        });

        reglInstance.current = reglInit({ gl });

        // TODO: figure out why this f*cks everything up :)
        // return () => {
        //     if (reglInstance.current) {
        //         reglInstance.current.destroy();
        //     }
        // }
    }, []);

    React.useEffect(() => {
        const regl:Regl = reglInstance.current;

        if(!regl) {
            return;
        }

        let _ = mat4.create();

        const drawBlob = regl({
            vert: `
                precision highp float;

                attribute vec3 position;

                uniform mat4 model, view, projection;
                uniform float seed, tick, scale, speed, magnitude;
                uniform vec2 deformationScale;

                //	Noise fn by Morgan McGuire @morgan3d, http://graphicscodex.com
                //	<https://www.shadertoy.com/view/4dS3Wd>

                float hash(float n) { return fract(sin(n) * 1e4); }

                float noise(float x) {
                    float i = floor(x);
                    float f = fract(x);
                    float u = f * f * (3.0 - 2.0 * f);

                    return mix(hash(i), hash(i + 1.0), u);
                }

                void main() {
                    vec3 pos = position;

                    pos.x -= noise(pos.x*deformationScale.x + (tick + seed)*speed)*magnitude;
                    pos.z -= noise(pos.z*deformationScale.y + (tick + seed)*speed)*magnitude;

                    pos *= scale;

                    gl_Position = projection
                        * view
                        * model
                        * vec4(pos, 1);
                }
            `,
            frag: `
                precision mediump float;

                uniform vec3 color;

                void main() {
                    gl_FragColor = vec4(color, 1);
                }
            `,
            attributes: {
                position: mesh.positions,
            },
            elements: mesh.cells,
            uniforms: {
                color: blobColor,
                scale,
                deformationScale, // [x, z] deformation scales/sizes
                seed: seed.current,
                magnitude,
                speed,
                tick: (context:any): number =>
                    context.time * 100,
                model: rotation,
                projection: (
                    { viewportWidth, viewportHeight }:
                        { viewportWidth: number, viewportHeight: number }
                ): mat4 =>
                    mat4.perspective(_,
                        Math.PI / 4,
                        viewportWidth / viewportHeight,
                        0.01,
                        1000
                    ),
                view: (
                    { tick }: { tick: number }
                ): mat4 =>
                    mat4.lookAt(_,
                        // move cam origin (i.e. the camera) in a circle around the center
                        // [30 * Math.cos(tick * 0.01), 0, 30 * Math.sin(tick * 0.01)],

                        [0, 3.5, 40], // camera origin
                        [-1, 0, 0], // look at
                        [0, 1, 0] // up vector
                    )

            }
        })

        let animationFrame = regl.frame(() => {
            regl.clear({
                depth: 1,
                color: [0, 0, 0, 0]
            })

            drawBlob({ magnitude });
        });

        return () => {
            if (animationFrame) {
                animationFrame.cancel();
            }
        };
    }, [reglInstance.current, magnitude]);

    return (
        <div className={`${s.ReglContainer} ${wrapperClassName}`}>
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
            />
        </div>
    );
};

Blob.defaultProps = {
    color: '#ffe7de',
    rotation: mat4.create(),
    width: 1400,
    height: 1000
}

export default Blob;
