import * as React from 'react';
import classNames from 'classnames';

import Link from '../Link';

import s from './Menu.module.scss';

export interface MenuProps {
    activeId: string;
    items: any[];
    color: string;
}

const Menu: React.FC<MenuProps> = ({ activeId, items, color = 'black' }) => {
    return (
        <>
            <nav className={s.Root}>
                {items && (
                    <ul className={s.List}>
                        {items.map((item, index) => {
                            let classes = classNames(s.Item, {
                                [s.ItemCurrent]: item.linkPageId === activeId,
                                [s.ItemLight]: color === 'purple',
                                [s.ItemWhite]: color === 'white',
                            });

                            return (
                                <li key={index} className={classes}>
                                    <Link className={s.Link} href={item.url}>
                                        {item.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </nav>
        </>
    );
};

export default Menu;
