export default {
  positions: [
    [
      -0.192505,
      0.01034,
      -0.345244
    ],
    [
      -0.157843,
      0.01034,
      -0.345666
    ],
    [
      -0.175195,
      0.01034,
      -0.346298
    ],
    [
      -0.140483,
      0.01034,
      -0.343508
    ],
    [
      -0.20974,
      0.01034,
      -0.342344
    ],
    [
      -0.123148,
      0.01034,
      -0.339985
    ],
    [
      -0.226866,
      0.01034,
      -0.337436
    ],
    [
      -0.105871,
      0.01034,
      -0.335258
    ],
    [
      -0.24385,
      0.01034,
      -0.330361
    ],
    [
      -0.088686,
      0.01034,
      -0.329488
    ],
    [
      -0.260659,
      0.01034,
      -0.320958
    ],
    [
      -0.071579,
      0.01034,
      -0.322854
    ],
    [
      -0.054509,
      0.01034,
      -0.315543
    ],
    [
      -0.277258,
      0.01034,
      -0.309065
    ],
    [
      -0.032993,
      0.01034,
      -0.305637
    ],
    [
      -0.293614,
      0.01034,
      -0.294523
    ],
    [
      0.013632,
      0.01034,
      -0.282886
    ],
    [
      -0.318521,
      0.01034,
      -0.270232
    ],
    [
      0.030602,
      0.01034,
      -0.274747
    ],
    [
      0.047637,
      0.01034,
      -0.266921
    ],
    [
      0.064696,
      0.01034,
      -0.259598
    ],
    [
      -0.337465,
      0.01034,
      -0.252202
    ],
    [
      0.081789,
      0.01034,
      -0.252949
    ],
    [
      0.098924,
      0.01034,
      -0.247142
    ],
    [
      -0.350929,
      0.01034,
      -0.239724
    ],
    [
      0.116109,
      0.01034,
      -0.242346
    ],
    [
      0.139776,
      0.01034,
      -0.237551
    ],
    [
      -0.363952,
      0.01034,
      -0.228045
    ],
    [
      0.163532,
      0.01034,
      -0.234428
    ],
    [
      0.188292,
      0.01034,
      -0.232362
    ],
    [
      0.235004,
      0.01034,
      -0.229716
    ],
    [
      0.25879,
      0.01034,
      -0.227908
    ],
    [
      -0.376612,
      0.01034,
      -0.217157
    ],
    [
      0.282502,
      0.01034,
      -0.225087
    ],
    [
      0.306109,
      0.01034,
      -0.220716
    ],
    [
      0.329579,
      0.01034,
      -0.214258
    ],
    [
      -0.388989,
      0.01034,
      -0.20705
    ],
    [
      0.352879,
      0.01034,
      -0.205175
    ],
    [
      -0.401165,
      0.01034,
      -0.197714
    ],
    [
      0.375977,
      0.01034,
      -0.192932
    ],
    [
      -0.413219,
      0.01034,
      -0.18914
    ],
    [
      0.398841,
      0.01034,
      -0.176991
    ],
    [
      -0.425231,
      0.01034,
      -0.18132
    ],
    [
      -0.437281,
      0.01034,
      -0.174243
    ],
    [
      0.404683,
      0.01034,
      -0.172171
    ],
    [
      -0.449745,
      0.01034,
      -0.167778
    ],
    [
      0.410515,
      0.01034,
      -0.166894
    ],
    [
      0.416296,
      0.01034,
      -0.161101
    ],
    [
      -0.456379,
      0.01034,
      -0.164559
    ],
    [
      -0.460584,
      0.01034,
      -0.161982
    ],
    [
      -0.465001,
      0.01034,
      -0.158385
    ],
    [
      0.421986,
      0.01034,
      -0.154735
    ],
    [
      -0.469421,
      0.01034,
      -0.153458
    ],
    [
      0.427547,
      0.01034,
      -0.147735
    ],
    [
      -0.473634,
      0.01034,
      -0.146888
    ],
    [
      0.432937,
      0.01034,
      -0.140045
    ],
    [
      -0.47743,
      0.01034,
      -0.138364
    ],
    [
      0.438118,
      0.01034,
      -0.131605
    ],
    [
      -0.480598,
      0.01034,
      -0.127575
    ],
    [
      0.44305,
      0.01034,
      -0.122358
    ],
    [
      -0.48293,
      0.01034,
      -0.114207
    ],
    [
      0.447693,
      0.01034,
      -0.112243
    ],
    [
      -0.484214,
      0.01034,
      -0.097951
    ],
    [
      0.452007,
      0.01034,
      -0.101203
    ],
    [
      0.455953,
      0.01034,
      -0.08918
    ],
    [
      -0.484241,
      0.01034,
      -0.078494
    ],
    [
      0.459491,
      0.01034,
      -0.076114
    ],
    [
      -0.482801,
      0.01034,
      -0.055524
    ],
    [
      0.462178,
      0.01034,
      -0.064017
    ],
    [
      0.464476,
      0.01034,
      -0.051464
    ],
    [
      -0.479752,
      0.01034,
      -0.032173
    ],
    [
      0.466413,
      0.01034,
      -0.03851
    ],
    [
      0.468022,
      0.01034,
      -0.025206
    ],
    [
      -0.475214,
      0.01034,
      -0.011666
    ],
    [
      0.469332,
      0.01034,
      -0.011605
    ],
    [
      -0.469368,
      0.01034,
      0.006164
    ],
    [
      0.470373,
      0.01034,
      0.002239
    ],
    [
      0.471482,
      0.01034,
      0.022394
    ],
    [
      -0.462395,
      0.01034,
      0.021487
    ],
    [
      -0.454477,
      0.01034,
      0.03447
    ],
    [
      0.472328,
      0.01034,
      0.050564
    ],
    [
      -0.445796,
      0.01034,
      0.045282
    ],
    [
      -0.436532,
      0.01034,
      0.054091
    ],
    [
      -0.426866,
      0.01034,
      0.061066
    ],
    [
      -0.295589,
      0.01034,
      0.059565
    ],
    [
      0.472656,
      0.01034,
      0.079942
    ],
    [
      -0.30479,
      0.01034,
      0.059906
    ],
    [
      -0.286397,
      0.01034,
      0.060315
    ],
    [
      -0.313998,
      0.01034,
      0.061107
    ],
    [
      -0.277213,
      0.01034,
      0.062388
    ],
    [
      -0.416981,
      0.01034,
      0.066374
    ],
    [
      -0.323213,
      0.01034,
      0.062938
    ],
    [
      -0.269516,
      0.01034,
      0.065279
    ],
    [
      -0.332434,
      0.01034,
      0.065168
    ],
    [
      -0.349991,
      0.01034,
      0.069695
    ],
    [
      -0.261899,
      0.01034,
      0.069145
    ],
    [
      -0.407058,
      0.01034,
      0.070185
    ],
    [
      -0.254355,
      0.01034,
      0.073879
    ],
    [
      -0.360119,
      0.01034,
      0.07194
    ],
    [
      -0.397277,
      0.01034,
      0.072667
    ],
    [
      -0.369352,
      0.01034,
      0.073456
    ],
    [
      -0.387821,
      0.01034,
      0.073989
    ],
    [
      -0.378586,
      0.01034,
      0.074215
    ],
    [
      -0.246876,
      0.01034,
      0.079377
    ],
    [
      -0.239454,
      0.01034,
      0.085533
    ],
    [
      -0.232083,
      0.01034,
      0.092244
    ],
    [
      0.472604,
      0.01034,
      0.112515
    ],
    [
      -0.224755,
      0.01034,
      0.099404
    ],
    [
      -0.213924,
      0.01034,
      0.110605
    ],
    [
      0.472229,
      0.01034,
      0.134276
    ],
    [
      -0.189213,
      0.01034,
      0.137507
    ],
    [
      0.471745,
      0.01034,
      0.149902
    ],
    [
      -0.172204,
      0.01034,
      0.155188
    ],
    [
      0.471027,
      0.01034,
      0.165398
    ],
    [
      -0.155705,
      0.01034,
      0.171088
    ],
    [
      0.470038,
      0.01034,
      0.180681
    ],
    [
      -0.13902,
      0.01034,
      0.185752
    ],
    [
      0.107673,
      0.01034,
      0.178853
    ],
    [
      0.099856,
      0.01034,
      0.180209
    ],
    [
      0.121217,
      0.01034,
      0.179064
    ],
    [
      0.134608,
      0.01034,
      0.182267
    ],
    [
      0.092088,
      0.01034,
      0.182542
    ],
    [
      0.468738,
      0.01034,
      0.195667
    ],
    [
      0.147866,
      0.01034,
      0.188066
    ],
    [
      0.08436,
      0.01034,
      0.185702
    ],
    [
      0.076665,
      0.01034,
      0.189539
    ],
    [
      -0.122173,
      0.01034,
      0.198963
    ],
    [
      0.161014,
      0.01034,
      0.196065
    ],
    [
      0.068995,
      0.01034,
      0.193903
    ],
    [
      0.060141,
      0.01034,
      0.199398
    ],
    [
      0.467089,
      0.01034,
      0.210271
    ],
    [
      0.17407,
      0.01034,
      0.205869
    ],
    [
      -0.105191,
      0.01034,
      0.210507
    ],
    [
      0.039683,
      0.01034,
      0.212806
    ],
    [
      0.187056,
      0.01034,
      0.217082
    ],
    [
      0.465052,
      0.01034,
      0.22441
    ],
    [
      -0.0881,
      0.01034,
      0.220169
    ],
    [
      0.030736,
      0.01034,
      0.218349
    ],
    [
      0.199992,
      0.01034,
      0.229308
    ],
    [
      0.023047,
      0.01034,
      0.22271
    ],
    [
      -0.070924,
      0.01034,
      0.227732
    ],
    [
      0.015326,
      0.01034,
      0.226543
    ],
    [
      0.462588,
      0.01034,
      0.237999
    ],
    [
      -0.001888,
      0.01034,
      0.232699
    ],
    [
      -0.053689,
      0.01034,
      0.232982
    ],
    [
      0.212899,
      0.01034,
      0.24215
    ],
    [
      -0.019146,
      0.01034,
      0.235681
    ],
    [
      -0.036421,
      0.01034,
      0.235703
    ],
    [
      0.459659,
      0.01034,
      0.250955
    ],
    [
      0.236777,
      0.01034,
      0.266237
    ],
    [
      0.456226,
      0.01034,
      0.263194
    ],
    [
      0.451851,
      0.01034,
      0.275669
    ],
    [
      0.251652,
      0.01034,
      0.280422
    ],
    [
      0.447039,
      0.01034,
      0.286614
    ],
    [
      0.264648,
      0.01034,
      0.291775
    ],
    [
      0.441839,
      0.01034,
      0.296126
    ],
    [
      0.274447,
      0.01034,
      0.299439
    ],
    [
      0.4363,
      0.01034,
      0.304302
    ],
    [
      0.284325,
      0.01034,
      0.306349
    ],
    [
      0.43047,
      0.01034,
      0.31124
    ],
    [
      0.294272,
      0.01034,
      0.312501
    ],
    [
      0.424398,
      0.01034,
      0.317038
    ],
    [
      0.304282,
      0.01034,
      0.317893
    ],
    [
      0.418132,
      0.01034,
      0.321792
    ],
    [
      0.314345,
      0.01034,
      0.322521
    ],
    [
      0.41172,
      0.01034,
      0.3256
    ],
    [
      0.324453,
      0.01034,
      0.326384
    ],
    [
      0.405212,
      0.01034,
      0.328559
    ],
    [
      0.334598,
      0.01034,
      0.329478
    ],
    [
      0.398656,
      0.01034,
      0.330767
    ],
    [
      0.344773,
      0.01034,
      0.331801
    ],
    [
      0.3921,
      0.01034,
      0.332321
    ],
    [
      0.354968,
      0.01034,
      0.333349
    ],
    [
      0.385593,
      0.01034,
      0.333317
    ],
    [
      0.375386,
      0.01034,
      0.33411
    ],
    [
      0.365175,
      0.01034,
      0.334119
    ],
    [
      -0.157843,
      0.005776,
      -0.345666
    ],
    [
      -0.192505,
      0.005776,
      -0.345244
    ],
    [
      -0.175195,
      0.005776,
      -0.346298
    ],
    [
      -0.140483,
      0.005776,
      -0.343508
    ],
    [
      -0.20974,
      0.005776,
      -0.342344
    ],
    [
      -0.123148,
      0.005776,
      -0.339985
    ],
    [
      -0.226866,
      0.005776,
      -0.337436
    ],
    [
      -0.105871,
      0.005776,
      -0.335258
    ],
    [
      -0.24385,
      0.005776,
      -0.330361
    ],
    [
      -0.088686,
      0.005776,
      -0.329488
    ],
    [
      -0.260659,
      0.005776,
      -0.320958
    ],
    [
      -0.071579,
      0.005776,
      -0.322854
    ],
    [
      -0.054509,
      0.005776,
      -0.315543
    ],
    [
      -0.277258,
      0.005776,
      -0.309065
    ],
    [
      -0.032993,
      0.005776,
      -0.305637
    ],
    [
      -0.293614,
      0.005776,
      -0.294523
    ],
    [
      0.013632,
      0.005776,
      -0.282886
    ],
    [
      -0.318521,
      0.005776,
      -0.270232
    ],
    [
      0.030602,
      0.005776,
      -0.274747
    ],
    [
      0.047637,
      0.005776,
      -0.266921
    ],
    [
      0.064696,
      0.005776,
      -0.259598
    ],
    [
      -0.337465,
      0.005776,
      -0.252202
    ],
    [
      0.081789,
      0.005776,
      -0.252949
    ],
    [
      0.098924,
      0.005776,
      -0.247142
    ],
    [
      -0.350929,
      0.005776,
      -0.239724
    ],
    [
      0.116109,
      0.005776,
      -0.242346
    ],
    [
      0.139776,
      0.005776,
      -0.237551
    ],
    [
      -0.363952,
      0.005776,
      -0.228045
    ],
    [
      0.163532,
      0.005776,
      -0.234428
    ],
    [
      0.188292,
      0.005776,
      -0.232362
    ],
    [
      0.235004,
      0.005776,
      -0.229716
    ],
    [
      0.25879,
      0.005776,
      -0.227908
    ],
    [
      -0.376612,
      0.005776,
      -0.217157
    ],
    [
      0.282502,
      0.005776,
      -0.225087
    ],
    [
      0.306109,
      0.005776,
      -0.220716
    ],
    [
      0.329579,
      0.005776,
      -0.214258
    ],
    [
      -0.388989,
      0.005776,
      -0.20705
    ],
    [
      0.352879,
      0.005776,
      -0.205175
    ],
    [
      -0.401165,
      0.005776,
      -0.197714
    ],
    [
      0.375977,
      0.005776,
      -0.192932
    ],
    [
      -0.413219,
      0.005776,
      -0.18914
    ],
    [
      0.398841,
      0.005776,
      -0.176991
    ],
    [
      -0.425231,
      0.005776,
      -0.18132
    ],
    [
      -0.437281,
      0.005776,
      -0.174243
    ],
    [
      0.404683,
      0.005776,
      -0.172171
    ],
    [
      -0.449745,
      0.005776,
      -0.167778
    ],
    [
      0.410515,
      0.005776,
      -0.166894
    ],
    [
      0.416296,
      0.005776,
      -0.161101
    ],
    [
      -0.456379,
      0.005776,
      -0.164559
    ],
    [
      -0.460584,
      0.005776,
      -0.161982
    ],
    [
      -0.465001,
      0.005776,
      -0.158385
    ],
    [
      0.421986,
      0.005776,
      -0.154735
    ],
    [
      -0.469421,
      0.005776,
      -0.153458
    ],
    [
      0.427547,
      0.005776,
      -0.147735
    ],
    [
      -0.473634,
      0.005776,
      -0.146888
    ],
    [
      0.432937,
      0.005776,
      -0.140045
    ],
    [
      -0.47743,
      0.005776,
      -0.138364
    ],
    [
      0.438118,
      0.005776,
      -0.131605
    ],
    [
      -0.480598,
      0.005776,
      -0.127575
    ],
    [
      0.44305,
      0.005776,
      -0.122358
    ],
    [
      -0.48293,
      0.005776,
      -0.114207
    ],
    [
      0.447693,
      0.005776,
      -0.112243
    ],
    [
      -0.484214,
      0.005776,
      -0.097951
    ],
    [
      0.452007,
      0.005776,
      -0.101203
    ],
    [
      0.455953,
      0.005776,
      -0.08918
    ],
    [
      -0.484241,
      0.005776,
      -0.078494
    ],
    [
      0.459491,
      0.005776,
      -0.076114
    ],
    [
      -0.482801,
      0.005776,
      -0.055524
    ],
    [
      0.462178,
      0.005776,
      -0.064017
    ],
    [
      0.464476,
      0.005776,
      -0.051464
    ],
    [
      -0.479752,
      0.005776,
      -0.032173
    ],
    [
      0.466413,
      0.005776,
      -0.03851
    ],
    [
      0.468022,
      0.005776,
      -0.025206
    ],
    [
      -0.475214,
      0.005776,
      -0.011666
    ],
    [
      0.469332,
      0.005776,
      -0.011605
    ],
    [
      -0.469368,
      0.005776,
      0.006164
    ],
    [
      0.470373,
      0.005776,
      0.002239
    ],
    [
      0.471482,
      0.005776,
      0.022394
    ],
    [
      -0.462395,
      0.005776,
      0.021487
    ],
    [
      -0.454477,
      0.005776,
      0.03447
    ],
    [
      0.472328,
      0.005776,
      0.050564
    ],
    [
      -0.445796,
      0.005776,
      0.045282
    ],
    [
      -0.436532,
      0.005776,
      0.054091
    ],
    [
      -0.295589,
      0.005776,
      0.059565
    ],
    [
      -0.426866,
      0.005776,
      0.061066
    ],
    [
      0.472656,
      0.005776,
      0.079942
    ],
    [
      -0.30479,
      0.005776,
      0.059906
    ],
    [
      -0.286397,
      0.005776,
      0.060315
    ],
    [
      -0.313998,
      0.005776,
      0.061107
    ],
    [
      -0.277213,
      0.005776,
      0.062388
    ],
    [
      -0.416981,
      0.005776,
      0.066374
    ],
    [
      -0.323213,
      0.005776,
      0.062938
    ],
    [
      -0.269516,
      0.005776,
      0.065279
    ],
    [
      -0.332434,
      0.005776,
      0.065168
    ],
    [
      -0.349991,
      0.005776,
      0.069695
    ],
    [
      -0.261899,
      0.005776,
      0.069145
    ],
    [
      -0.407058,
      0.005776,
      0.070185
    ],
    [
      -0.254355,
      0.005776,
      0.073879
    ],
    [
      -0.360119,
      0.005776,
      0.07194
    ],
    [
      -0.397277,
      0.005776,
      0.072667
    ],
    [
      -0.369352,
      0.005776,
      0.073456
    ],
    [
      -0.387821,
      0.005776,
      0.073989
    ],
    [
      -0.378586,
      0.005776,
      0.074215
    ],
    [
      -0.246876,
      0.005776,
      0.079377
    ],
    [
      -0.239454,
      0.005776,
      0.085533
    ],
    [
      -0.232083,
      0.005776,
      0.092244
    ],
    [
      0.472604,
      0.005776,
      0.112515
    ],
    [
      -0.224755,
      0.005776,
      0.099404
    ],
    [
      -0.213924,
      0.005776,
      0.110605
    ],
    [
      0.472229,
      0.005776,
      0.134276
    ],
    [
      -0.189213,
      0.005776,
      0.137507
    ],
    [
      0.471745,
      0.005776,
      0.149902
    ],
    [
      -0.172204,
      0.005776,
      0.155188
    ],
    [
      0.471027,
      0.005776,
      0.165398
    ],
    [
      -0.155705,
      0.005776,
      0.171088
    ],
    [
      0.470038,
      0.005776,
      0.180681
    ],
    [
      0.107673,
      0.005776,
      0.178853
    ],
    [
      -0.13902,
      0.005776,
      0.185752
    ],
    [
      0.099856,
      0.005776,
      0.180209
    ],
    [
      0.121217,
      0.005776,
      0.179064
    ],
    [
      0.134608,
      0.005776,
      0.182267
    ],
    [
      0.092088,
      0.005776,
      0.182542
    ],
    [
      0.468738,
      0.005776,
      0.195667
    ],
    [
      0.147866,
      0.005776,
      0.188066
    ],
    [
      0.08436,
      0.005776,
      0.185702
    ],
    [
      0.076665,
      0.005776,
      0.189539
    ],
    [
      -0.122173,
      0.005776,
      0.198963
    ],
    [
      0.161014,
      0.005776,
      0.196065
    ],
    [
      0.068995,
      0.005776,
      0.193903
    ],
    [
      0.060141,
      0.005776,
      0.199398
    ],
    [
      0.467089,
      0.005776,
      0.210271
    ],
    [
      0.17407,
      0.005776,
      0.205869
    ],
    [
      -0.105191,
      0.005776,
      0.210507
    ],
    [
      0.039683,
      0.005776,
      0.212806
    ],
    [
      0.187056,
      0.005776,
      0.217082
    ],
    [
      0.465052,
      0.005776,
      0.22441
    ],
    [
      -0.0881,
      0.005776,
      0.220169
    ],
    [
      0.030736,
      0.005776,
      0.218349
    ],
    [
      0.199992,
      0.005776,
      0.229308
    ],
    [
      0.023047,
      0.005776,
      0.22271
    ],
    [
      -0.070924,
      0.005776,
      0.227732
    ],
    [
      0.015326,
      0.005776,
      0.226543
    ],
    [
      0.462588,
      0.005776,
      0.237999
    ],
    [
      -0.001888,
      0.005776,
      0.232699
    ],
    [
      -0.053689,
      0.005776,
      0.232982
    ],
    [
      0.212899,
      0.005776,
      0.24215
    ],
    [
      -0.019146,
      0.005776,
      0.235681
    ],
    [
      -0.036421,
      0.005776,
      0.235703
    ],
    [
      0.459659,
      0.005776,
      0.250955
    ],
    [
      0.236777,
      0.005776,
      0.266237
    ],
    [
      0.456226,
      0.005776,
      0.263194
    ],
    [
      0.451851,
      0.005776,
      0.275669
    ],
    [
      0.251652,
      0.005776,
      0.280422
    ],
    [
      0.447039,
      0.005776,
      0.286614
    ],
    [
      0.264648,
      0.005776,
      0.291775
    ],
    [
      0.441839,
      0.005776,
      0.296126
    ],
    [
      0.274447,
      0.005776,
      0.299439
    ],
    [
      0.4363,
      0.005776,
      0.304302
    ],
    [
      0.284325,
      0.005776,
      0.306349
    ],
    [
      0.43047,
      0.005776,
      0.31124
    ],
    [
      0.294272,
      0.005776,
      0.312501
    ],
    [
      0.424398,
      0.005776,
      0.317038
    ],
    [
      0.304282,
      0.005776,
      0.317893
    ],
    [
      0.418132,
      0.005776,
      0.321792
    ],
    [
      0.314345,
      0.005776,
      0.322521
    ],
    [
      0.41172,
      0.005776,
      0.3256
    ],
    [
      0.324453,
      0.005776,
      0.326384
    ],
    [
      0.405212,
      0.005776,
      0.328559
    ],
    [
      0.334598,
      0.005776,
      0.329478
    ],
    [
      0.398656,
      0.005776,
      0.330767
    ],
    [
      0.344773,
      0.005776,
      0.331801
    ],
    [
      0.3921,
      0.005776,
      0.332321
    ],
    [
      0.354968,
      0.005776,
      0.333349
    ],
    [
      0.385593,
      0.005776,
      0.333317
    ],
    [
      0.375386,
      0.005776,
      0.33411
    ],
    [
      0.365175,
      0.005776,
      0.334119
    ],
    [
      -0.442887,
      0.01034,
      -0.171112
    ],
    [
      -0.418583,
      0.01034,
      -0.18547
    ],
    [
      -0.427465,
      0.005776,
      -0.179706
    ],
    [
      -0.418583,
      0.01034,
      -0.18547
    ],
    [
      -0.394644,
      0.01034,
      -0.20256
    ],
    [
      -0.396765,
      0.005776,
      -0.200942
    ],
    [
      -0.396765,
      0.005776,
      -0.200942
    ],
    [
      -0.427465,
      0.005776,
      -0.179706
    ],
    [
      -0.418583,
      0.01034,
      -0.18547
    ],
    [
      -0.394644,
      0.01034,
      -0.20256
    ],
    [
      -0.359187,
      0.01034,
      -0.232011
    ],
    [
      -0.372265,
      0.005776,
      -0.220782
    ],
    [
      -0.372265,
      0.005776,
      -0.220782
    ],
    [
      -0.396765,
      0.005776,
      -0.200942
    ],
    [
      -0.394644,
      0.01034,
      -0.20256
    ],
    [
      -0.33751,
      0.005776,
      -0.251987
    ],
    [
      -0.372265,
      0.005776,
      -0.220782
    ],
    [
      -0.359187,
      0.01034,
      -0.232011
    ],
    [
      -0.359187,
      0.01034,
      -0.232011
    ],
    [
      -0.282125,
      0.01034,
      -0.305314
    ],
    [
      -0.33751,
      0.005776,
      -0.251987
    ],
    [
      -0.282394,
      0.005776,
      -0.304975
    ],
    [
      -0.33751,
      0.005776,
      -0.251987
    ],
    [
      -0.282125,
      0.01034,
      -0.305314
    ],
    [
      -0.282125,
      0.01034,
      -0.305314
    ],
    [
      -0.260659,
      0.01034,
      -0.320958
    ],
    [
      -0.260659,
      0.005776,
      -0.320958
    ],
    [
      -0.260659,
      0.005776,
      -0.320958
    ],
    [
      -0.282394,
      0.005776,
      -0.304975
    ],
    [
      -0.282125,
      0.01034,
      -0.305314
    ],
    [
      -0.260659,
      0.01034,
      -0.320958
    ],
    [
      -0.24385,
      0.01034,
      -0.330361
    ],
    [
      -0.24385,
      0.005776,
      -0.330361
    ],
    [
      -0.260659,
      0.005776,
      -0.320958
    ],
    [
      -0.24385,
      0.01034,
      -0.330361
    ],
    [
      -0.226866,
      0.01034,
      -0.337436
    ],
    [
      -0.226866,
      0.005776,
      -0.337436
    ],
    [
      -0.24385,
      0.005776,
      -0.330361
    ],
    [
      -0.226866,
      0.01034,
      -0.337436
    ],
    [
      -0.20974,
      0.01034,
      -0.342344
    ],
    [
      -0.20974,
      0.005776,
      -0.342344
    ],
    [
      -0.226866,
      0.005776,
      -0.337436
    ],
    [
      -0.20974,
      0.01034,
      -0.342344
    ],
    [
      -0.192505,
      0.01034,
      -0.345244
    ],
    [
      -0.192505,
      0.005776,
      -0.345244
    ],
    [
      -0.20974,
      0.005776,
      -0.342344
    ],
    [
      -0.192505,
      0.01034,
      -0.345244
    ],
    [
      -0.165666,
      0.01034,
      -0.346333
    ],
    [
      -0.168719,
      0.005776,
      -0.34643
    ],
    [
      -0.168719,
      0.005776,
      -0.34643
    ],
    [
      -0.192505,
      0.005776,
      -0.345244
    ],
    [
      -0.192505,
      0.01034,
      -0.345244
    ],
    [
      -0.165666,
      0.01034,
      -0.346333
    ],
    [
      -0.131149,
      0.01034,
      -0.341921
    ],
    [
      -0.13418,
      0.005776,
      -0.342522
    ],
    [
      -0.13418,
      0.005776,
      -0.342522
    ],
    [
      -0.168719,
      0.005776,
      -0.34643
    ],
    [
      -0.165666,
      0.01034,
      -0.346333
    ],
    [
      -0.131149,
      0.01034,
      -0.341921
    ],
    [
      -0.097055,
      0.01034,
      -0.332534
    ],
    [
      -0.100014,
      0.005776,
      -0.333511
    ],
    [
      -0.100014,
      0.005776,
      -0.333511
    ],
    [
      -0.13418,
      0.005776,
      -0.342522
    ],
    [
      -0.131149,
      0.01034,
      -0.341921
    ],
    [
      -0.097055,
      0.01034,
      -0.332534
    ],
    [
      -0.052341,
      0.01034,
      -0.314817
    ],
    [
      -0.066333,
      0.005776,
      -0.320738
    ],
    [
      -0.066333,
      0.005776,
      -0.320738
    ],
    [
      -0.100014,
      0.005776,
      -0.333511
    ],
    [
      -0.097055,
      0.01034,
      -0.332534
    ],
    [
      -0.031768,
      0.005776,
      -0.305073
    ],
    [
      -0.066333,
      0.005776,
      -0.320738
    ],
    [
      -0.052341,
      0.01034,
      -0.314817
    ],
    [
      -0.052341,
      0.01034,
      -0.314817
    ],
    [
      0.046467,
      0.01034,
      -0.267112
    ],
    [
      -0.031768,
      0.005776,
      -0.305073
    ],
    [
      0.045579,
      0.005776,
      -0.267603
    ],
    [
      -0.031768,
      0.005776,
      -0.305073
    ],
    [
      0.046467,
      0.01034,
      -0.267112
    ],
    [
      0.046467,
      0.01034,
      -0.267112
    ],
    [
      0.093078,
      0.01034,
      -0.248913
    ],
    [
      0.090127,
      0.005776,
      -0.249897
    ],
    [
      0.090127,
      0.005776,
      -0.249897
    ],
    [
      0.045579,
      0.005776,
      -0.267603
    ],
    [
      0.046467,
      0.01034,
      -0.267112
    ],
    [
      0.093078,
      0.01034,
      -0.248913
    ],
    [
      0.129575,
      0.01034,
      -0.239205
    ],
    [
      0.125395,
      0.005776,
      -0.240058
    ],
    [
      0.125395,
      0.005776,
      -0.240058
    ],
    [
      0.090127,
      0.005776,
      -0.249897
    ],
    [
      0.093078,
      0.01034,
      -0.248913
    ],
    [
      0.129575,
      0.01034,
      -0.239205
    ],
    [
      0.171924,
      0.01034,
      -0.23353
    ],
    [
      0.172274,
      0.005776,
      -0.233425
    ],
    [
      0.172274,
      0.005776,
      -0.233425
    ],
    [
      0.125395,
      0.005776,
      -0.240058
    ],
    [
      0.129575,
      0.01034,
      -0.239205
    ],
    [
      0.171924,
      0.01034,
      -0.23353
    ],
    [
      0.251696,
      0.01034,
      -0.228653
    ],
    [
      0.172274,
      0.005776,
      -0.233425
    ],
    [
      0.251332,
      0.005776,
      -0.228638
    ],
    [
      0.172274,
      0.005776,
      -0.233425
    ],
    [
      0.251696,
      0.01034,
      -0.228653
    ],
    [
      0.251696,
      0.01034,
      -0.228653
    ],
    [
      0.298746,
      0.01034,
      -0.222478
    ],
    [
      0.282502,
      0.005776,
      -0.225087
    ],
    [
      0.282502,
      0.005776,
      -0.225087
    ],
    [
      0.251332,
      0.005776,
      -0.228638
    ],
    [
      0.251696,
      0.01034,
      -0.228653
    ],
    [
      0.306109,
      0.005776,
      -0.220716
    ],
    [
      0.282502,
      0.005776,
      -0.225087
    ],
    [
      0.298746,
      0.01034,
      -0.222478
    ],
    [
      0.298746,
      0.01034,
      -0.222478
    ],
    [
      0.329579,
      0.01034,
      -0.214258
    ],
    [
      0.329579,
      0.005776,
      -0.214258
    ],
    [
      0.329579,
      0.005776,
      -0.214258
    ],
    [
      0.306109,
      0.005776,
      -0.220716
    ],
    [
      0.298746,
      0.01034,
      -0.222478
    ],
    [
      0.329579,
      0.01034,
      -0.214258
    ],
    [
      0.352879,
      0.01034,
      -0.205175
    ],
    [
      0.352879,
      0.005776,
      -0.205175
    ],
    [
      0.329579,
      0.005776,
      -0.214258
    ],
    [
      0.352879,
      0.01034,
      -0.205175
    ],
    [
      0.375977,
      0.01034,
      -0.192932
    ],
    [
      0.375977,
      0.005776,
      -0.192932
    ],
    [
      0.352879,
      0.005776,
      -0.205175
    ],
    [
      0.375977,
      0.01034,
      -0.192932
    ],
    [
      0.404438,
      0.01034,
      -0.172594
    ],
    [
      0.402794,
      0.005776,
      -0.173991
    ],
    [
      0.402794,
      0.005776,
      -0.173991
    ],
    [
      0.375977,
      0.005776,
      -0.192932
    ],
    [
      0.375977,
      0.01034,
      -0.192932
    ],
    [
      0.404438,
      0.01034,
      -0.172594
    ],
    [
      0.41984,
      0.01034,
      -0.157304
    ],
    [
      0.418838,
      0.005776,
      -0.158429
    ],
    [
      0.418838,
      0.005776,
      -0.158429
    ],
    [
      0.402794,
      0.005776,
      -0.173991
    ],
    [
      0.404438,
      0.01034,
      -0.172594
    ],
    [
      0.41984,
      0.01034,
      -0.157304
    ],
    [
      0.430853,
      0.01034,
      -0.14325
    ],
    [
      0.429901,
      0.005776,
      -0.14461
    ],
    [
      0.429901,
      0.005776,
      -0.14461
    ],
    [
      0.418838,
      0.005776,
      -0.158429
    ],
    [
      0.41984,
      0.01034,
      -0.157304
    ],
    [
      0.430853,
      0.01034,
      -0.14325
    ],
    [
      0.441123,
      0.01034,
      -0.126286
    ],
    [
      0.440251,
      0.005776,
      -0.12792
    ],
    [
      0.440251,
      0.005776,
      -0.12792
    ],
    [
      0.429901,
      0.005776,
      -0.14461
    ],
    [
      0.430853,
      0.01034,
      -0.14325
    ],
    [
      0.441123,
      0.01034,
      -0.126286
    ],
    [
      0.450311,
      0.01034,
      -0.10598
    ],
    [
      0.449547,
      0.005776,
      -0.107928
    ],
    [
      0.449547,
      0.005776,
      -0.107928
    ],
    [
      0.440251,
      0.005776,
      -0.12792
    ],
    [
      0.441123,
      0.01034,
      -0.126286
    ],
    [
      0.450311,
      0.01034,
      -0.10598
    ],
    [
      0.458006,
      0.01034,
      -0.08221
    ],
    [
      0.457381,
      0.005776,
      -0.08449
    ],
    [
      0.457381,
      0.005776,
      -0.08449
    ],
    [
      0.449547,
      0.005776,
      -0.107928
    ],
    [
      0.450311,
      0.01034,
      -0.10598
    ],
    [
      0.458006,
      0.01034,
      -0.08221
    ],
    [
      0.465665,
      0.01034,
      -0.045473
    ],
    [
      0.465116,
      0.005776,
      -0.049054
    ],
    [
      0.465116,
      0.005776,
      -0.049054
    ],
    [
      0.457381,
      0.005776,
      -0.08449
    ],
    [
      0.458006,
      0.01034,
      -0.08221
    ],
    [
      0.465665,
      0.01034,
      -0.045473
    ],
    [
      0.470892,
      0.01034,
      0.008357
    ],
    [
      0.470665,
      0.005776,
      0.00452
    ],
    [
      0.470665,
      0.005776,
      0.00452
    ],
    [
      0.465116,
      0.005776,
      -0.049054
    ],
    [
      0.465665,
      0.01034,
      -0.045473
    ],
    [
      0.470892,
      0.01034,
      0.008357
    ],
    [
      0.472583,
      0.01034,
      0.064028
    ],
    [
      0.472534,
      0.005776,
      0.059989
    ],
    [
      0.472534,
      0.005776,
      0.059989
    ],
    [
      0.470665,
      0.005776,
      0.00452
    ],
    [
      0.470892,
      0.01034,
      0.008357
    ],
    [
      0.472583,
      0.01034,
      0.064028
    ],
    [
      0.47237,
      0.01034,
      0.132962
    ],
    [
      0.472453,
      0.005776,
      0.128866
    ],
    [
      0.472453,
      0.005776,
      0.128866
    ],
    [
      0.472534,
      0.005776,
      0.059989
    ],
    [
      0.472583,
      0.01034,
      0.064028
    ],
    [
      0.47237,
      0.01034,
      0.132962
    ],
    [
      0.469192,
      0.01034,
      0.193309
    ],
    [
      0.46956,
      0.005776,
      0.189207
    ],
    [
      0.46956,
      0.005776,
      0.189207
    ],
    [
      0.472453,
      0.005776,
      0.128866
    ],
    [
      0.47237,
      0.01034,
      0.132962
    ],
    [
      0.469192,
      0.01034,
      0.193309
    ],
    [
      0.463576,
      0.01034,
      0.233243
    ],
    [
      0.464011,
      0.005776,
      0.230886
    ],
    [
      0.464011,
      0.005776,
      0.230886
    ],
    [
      0.46956,
      0.005776,
      0.189207
    ],
    [
      0.469192,
      0.01034,
      0.193309
    ],
    [
      0.463576,
      0.01034,
      0.233243
    ],
    [
      0.457528,
      0.01034,
      0.259154
    ],
    [
      0.458129,
      0.005776,
      0.257059
    ],
    [
      0.458129,
      0.005776,
      0.257059
    ],
    [
      0.464011,
      0.005776,
      0.230886
    ],
    [
      0.463576,
      0.01034,
      0.233243
    ],
    [
      0.457528,
      0.01034,
      0.259154
    ],
    [
      0.449029,
      0.01034,
      0.282643
    ],
    [
      0.449882,
      0.005776,
      0.280724
    ],
    [
      0.449882,
      0.005776,
      0.280724
    ],
    [
      0.458129,
      0.005776,
      0.257059
    ],
    [
      0.457528,
      0.01034,
      0.259154
    ],
    [
      0.449029,
      0.01034,
      0.282643
    ],
    [
      0.438599,
      0.01034,
      0.301312
    ],
    [
      0.439583,
      0.005776,
      0.299872
    ],
    [
      0.439583,
      0.005776,
      0.299872
    ],
    [
      0.449882,
      0.005776,
      0.280724
    ],
    [
      0.449029,
      0.01034,
      0.282643
    ],
    [
      0.438599,
      0.01034,
      0.301312
    ],
    [
      0.426973,
      0.01034,
      0.314881
    ],
    [
      0.428052,
      0.005776,
      0.313854
    ],
    [
      0.428052,
      0.005776,
      0.313854
    ],
    [
      0.439583,
      0.005776,
      0.299872
    ],
    [
      0.438599,
      0.01034,
      0.301312
    ],
    [
      0.426973,
      0.01034,
      0.314881
    ],
    [
      0.414541,
      0.01034,
      0.324152
    ],
    [
      0.415677,
      0.005776,
      0.323475
    ],
    [
      0.415677,
      0.005776,
      0.323475
    ],
    [
      0.428052,
      0.005776,
      0.313854
    ],
    [
      0.426973,
      0.01034,
      0.314881
    ],
    [
      0.414541,
      0.01034,
      0.324152
    ],
    [
      0.401649,
      0.01034,
      0.329928
    ],
    [
      0.402802,
      0.005776,
      0.329533
    ],
    [
      0.402802,
      0.005776,
      0.329533
    ],
    [
      0.415677,
      0.005776,
      0.323475
    ],
    [
      0.414541,
      0.01034,
      0.324152
    ],
    [
      0.401649,
      0.01034,
      0.329928
    ],
    [
      0.383696,
      0.01034,
      0.333746
    ],
    [
      0.385854,
      0.005776,
      0.33349
    ],
    [
      0.385854,
      0.005776,
      0.33349
    ],
    [
      0.402802,
      0.005776,
      0.329533
    ],
    [
      0.401649,
      0.01034,
      0.329928
    ],
    [
      0.383696,
      0.01034,
      0.333746
    ],
    [
      0.359194,
      0.01034,
      0.333851
    ],
    [
      0.361,
      0.005776,
      0.333986
    ],
    [
      0.361,
      0.005776,
      0.333986
    ],
    [
      0.385854,
      0.005776,
      0.33349
    ],
    [
      0.383696,
      0.01034,
      0.333746
    ],
    [
      0.359194,
      0.01034,
      0.333851
    ],
    [
      0.33886,
      0.01034,
      0.330635
    ],
    [
      0.340659,
      0.005776,
      0.331044
    ],
    [
      0.340659,
      0.005776,
      0.331044
    ],
    [
      0.361,
      0.005776,
      0.333986
    ],
    [
      0.359194,
      0.01034,
      0.333851
    ],
    [
      0.33886,
      0.01034,
      0.330635
    ],
    [
      0.31861,
      0.01034,
      0.324336
    ],
    [
      0.320397,
      0.005776,
      0.325016
    ],
    [
      0.320397,
      0.005776,
      0.325016
    ],
    [
      0.340659,
      0.005776,
      0.331044
    ],
    [
      0.33886,
      0.01034,
      0.330635
    ],
    [
      0.31861,
      0.01034,
      0.324336
    ],
    [
      0.298509,
      0.01034,
      0.314971
    ],
    [
      0.300277,
      0.005776,
      0.31592
    ],
    [
      0.300277,
      0.005776,
      0.31592
    ],
    [
      0.320397,
      0.005776,
      0.325016
    ],
    [
      0.31861,
      0.01034,
      0.324336
    ],
    [
      0.298509,
      0.01034,
      0.314971
    ],
    [
      0.278635,
      0.01034,
      0.30256
    ],
    [
      0.275651,
      0.005776,
      0.300592
    ],
    [
      0.275651,
      0.005776,
      0.300592
    ],
    [
      0.300277,
      0.005776,
      0.31592
    ],
    [
      0.298509,
      0.01034,
      0.314971
    ],
    [
      0.278635,
      0.01034,
      0.30256
    ],
    [
      0.253587,
      0.01034,
      0.282462
    ],
    [
      0.275651,
      0.005776,
      0.300592
    ],
    [
      0.24596,
      0.005776,
      0.275286
    ],
    [
      0.275651,
      0.005776,
      0.300592
    ],
    [
      0.253587,
      0.01034,
      0.282462
    ],
    [
      0.253587,
      0.01034,
      0.282462
    ],
    [
      0.202447,
      0.01034,
      0.231678
    ],
    [
      0.24596,
      0.005776,
      0.275286
    ],
    [
      0.194957,
      0.005776,
      0.224267
    ],
    [
      0.24596,
      0.005776,
      0.275286
    ],
    [
      0.202447,
      0.01034,
      0.231678
    ],
    [
      0.202447,
      0.01034,
      0.231678
    ],
    [
      0.177737,
      0.01034,
      0.208771
    ],
    [
      0.194957,
      0.005776,
      0.224267
    ],
    [
      0.167129,
      0.005776,
      0.200253
    ],
    [
      0.194957,
      0.005776,
      0.224267
    ],
    [
      0.177737,
      0.01034,
      0.208771
    ],
    [
      0.177737,
      0.01034,
      0.208771
    ],
    [
      0.152028,
      0.01034,
      0.190151
    ],
    [
      0.167129,
      0.005776,
      0.200253
    ],
    [
      0.147866,
      0.005776,
      0.188066
    ],
    [
      0.167129,
      0.005776,
      0.200253
    ],
    [
      0.152028,
      0.01034,
      0.190151
    ],
    [
      0.152028,
      0.01034,
      0.190151
    ],
    [
      0.134608,
      0.01034,
      0.182267
    ],
    [
      0.134608,
      0.005776,
      0.182267
    ],
    [
      0.134608,
      0.005776,
      0.182267
    ],
    [
      0.147866,
      0.005776,
      0.188066
    ],
    [
      0.152028,
      0.01034,
      0.190151
    ],
    [
      0.134608,
      0.01034,
      0.182267
    ],
    [
      0.121217,
      0.01034,
      0.179064
    ],
    [
      0.121217,
      0.005776,
      0.179064
    ],
    [
      0.134608,
      0.005776,
      0.182267
    ],
    [
      0.121217,
      0.01034,
      0.179064
    ],
    [
      0.107673,
      0.01034,
      0.178853
    ],
    [
      0.107673,
      0.005776,
      0.178853
    ],
    [
      0.121217,
      0.005776,
      0.179064
    ],
    [
      0.107673,
      0.01034,
      0.178853
    ],
    [
      0.095862,
      0.01034,
      0.181189
    ],
    [
      0.097205,
      0.005776,
      0.180802
    ],
    [
      0.097205,
      0.005776,
      0.180802
    ],
    [
      0.107673,
      0.005776,
      0.178853
    ],
    [
      0.107673,
      0.01034,
      0.178853
    ],
    [
      0.095862,
      0.01034,
      0.181189
    ],
    [
      0.080662,
      0.01034,
      0.187397
    ],
    [
      0.081953,
      0.005776,
      0.186769
    ],
    [
      0.081953,
      0.005776,
      0.186769
    ],
    [
      0.097205,
      0.005776,
      0.180802
    ],
    [
      0.095862,
      0.01034,
      0.181189
    ],
    [
      0.080662,
      0.01034,
      0.187397
    ],
    [
      0.064424,
      0.01034,
      0.196634
    ],
    [
      0.06555,
      0.005776,
      0.195929
    ],
    [
      0.06555,
      0.005776,
      0.195929
    ],
    [
      0.081953,
      0.005776,
      0.186769
    ],
    [
      0.080662,
      0.01034,
      0.187397
    ],
    [
      0.064424,
      0.01034,
      0.196634
    ],
    [
      0.034233,
      0.01034,
      0.216298
    ],
    [
      0.03539,
      0.005776,
      0.215575
    ],
    [
      0.03539,
      0.005776,
      0.215575
    ],
    [
      0.06555,
      0.005776,
      0.195929
    ],
    [
      0.064424,
      0.01034,
      0.196634
    ],
    [
      0.034233,
      0.01034,
      0.216298
    ],
    [
      0.016816,
      0.01034,
      0.225945
    ],
    [
      0.017814,
      0.005776,
      0.225488
    ],
    [
      0.017814,
      0.005776,
      0.225488
    ],
    [
      0.03539,
      0.005776,
      0.215575
    ],
    [
      0.034233,
      0.01034,
      0.216298
    ],
    [
      0.016816,
      0.01034,
      0.225945
    ],
    [
      -0.001888,
      0.01034,
      0.232699
    ],
    [
      -0.001888,
      0.005776,
      0.232699
    ],
    [
      -0.001888,
      0.005776,
      0.232699
    ],
    [
      0.017814,
      0.005776,
      0.225488
    ],
    [
      0.016816,
      0.01034,
      0.225945
    ],
    [
      -0.001888,
      0.01034,
      0.232699
    ],
    [
      -0.019146,
      0.01034,
      0.235681
    ],
    [
      -0.019146,
      0.005776,
      0.235681
    ],
    [
      -0.001888,
      0.005776,
      0.232699
    ],
    [
      -0.019146,
      0.01034,
      0.235681
    ],
    [
      -0.036421,
      0.01034,
      0.235703
    ],
    [
      -0.036421,
      0.005776,
      0.235703
    ],
    [
      -0.019146,
      0.005776,
      0.235681
    ],
    [
      -0.036421,
      0.01034,
      0.235703
    ],
    [
      -0.053689,
      0.01034,
      0.232982
    ],
    [
      -0.053689,
      0.005776,
      0.232982
    ],
    [
      -0.036421,
      0.005776,
      0.235703
    ],
    [
      -0.053689,
      0.01034,
      0.232982
    ],
    [
      -0.070924,
      0.01034,
      0.227732
    ],
    [
      -0.070924,
      0.005776,
      0.227732
    ],
    [
      -0.053689,
      0.005776,
      0.232982
    ],
    [
      -0.070924,
      0.01034,
      0.227732
    ],
    [
      -0.0881,
      0.01034,
      0.220169
    ],
    [
      -0.0881,
      0.005776,
      0.220169
    ],
    [
      -0.070924,
      0.005776,
      0.227732
    ],
    [
      -0.0881,
      0.01034,
      0.220169
    ],
    [
      -0.114162,
      0.01034,
      0.204857
    ],
    [
      -0.111212,
      0.005776,
      0.206833
    ],
    [
      -0.111212,
      0.005776,
      0.206833
    ],
    [
      -0.0881,
      0.005776,
      0.220169
    ],
    [
      -0.0881,
      0.01034,
      0.220169
    ],
    [
      -0.114162,
      0.01034,
      0.204857
    ],
    [
      -0.147771,
      0.01034,
      0.178423
    ],
    [
      -0.144881,
      0.005776,
      0.180938
    ],
    [
      -0.144881,
      0.005776,
      0.180938
    ],
    [
      -0.111212,
      0.005776,
      0.206833
    ],
    [
      -0.114162,
      0.01034,
      0.204857
    ],
    [
      -0.147771,
      0.01034,
      0.178423
    ],
    [
      -0.180613,
      0.01034,
      0.146749
    ],
    [
      -0.179756,
      0.005776,
      0.147703
    ],
    [
      -0.179756,
      0.005776,
      0.147703
    ],
    [
      -0.144881,
      0.005776,
      0.180938
    ],
    [
      -0.147771,
      0.01034,
      0.178423
    ],
    [
      -0.180613,
      0.01034,
      0.146749
    ],
    [
      -0.223604,
      0.01034,
      0.100302
    ],
    [
      -0.179756,
      0.005776,
      0.147703
    ],
    [
      -0.223456,
      0.005776,
      0.100531
    ],
    [
      -0.179756,
      0.005776,
      0.147703
    ],
    [
      -0.223604,
      0.01034,
      0.100302
    ],
    [
      -0.223604,
      0.01034,
      0.100302
    ],
    [
      -0.244368,
      0.01034,
      0.081313
    ],
    [
      -0.243095,
      0.005776,
      0.082356
    ],
    [
      -0.243095,
      0.005776,
      0.082356
    ],
    [
      -0.223456,
      0.005776,
      0.100531
    ],
    [
      -0.223604,
      0.01034,
      0.100302
    ],
    [
      -0.244368,
      0.01034,
      0.081313
    ],
    [
      -0.259269,
      0.01034,
      0.070603
    ],
    [
      -0.257962,
      0.005776,
      0.071408
    ],
    [
      -0.257962,
      0.005776,
      0.071408
    ],
    [
      -0.243095,
      0.005776,
      0.082356
    ],
    [
      -0.244368,
      0.01034,
      0.081313
    ],
    [
      -0.259269,
      0.01034,
      0.070603
    ],
    [
      -0.274561,
      0.01034,
      0.063143
    ],
    [
      -0.273226,
      0.005776,
      0.063625
    ],
    [
      -0.273226,
      0.005776,
      0.063625
    ],
    [
      -0.257962,
      0.005776,
      0.071408
    ],
    [
      -0.259269,
      0.01034,
      0.070603
    ],
    [
      -0.274561,
      0.01034,
      0.063143
    ],
    [
      -0.291197,
      0.01034,
      0.059637
    ],
    [
      -0.289593,
      0.005776,
      0.059784
    ],
    [
      -0.289593,
      0.005776,
      0.059784
    ],
    [
      -0.273226,
      0.005776,
      0.063625
    ],
    [
      -0.274561,
      0.01034,
      0.063143
    ],
    [
      -0.291197,
      0.01034,
      0.059637
    ],
    [
      -0.309078,
      0.01034,
      0.060287
    ],
    [
      -0.307552,
      0.005776,
      0.060107
    ],
    [
      -0.307552,
      0.005776,
      0.060107
    ],
    [
      -0.289593,
      0.005776,
      0.059784
    ],
    [
      -0.291197,
      0.01034,
      0.059637
    ],
    [
      -0.309078,
      0.01034,
      0.060287
    ],
    [
      -0.325736,
      0.01034,
      0.06349
    ],
    [
      -0.325435,
      0.005776,
      0.06341
    ],
    [
      -0.325435,
      0.005776,
      0.06341
    ],
    [
      -0.307552,
      0.005776,
      0.060107
    ],
    [
      -0.309078,
      0.01034,
      0.060287
    ],
    [
      -0.325736,
      0.01034,
      0.06349
    ],
    [
      -0.356352,
      0.01034,
      0.071244
    ],
    [
      -0.325435,
      0.005776,
      0.06341
    ],
    [
      -0.356694,
      0.005776,
      0.071278
    ],
    [
      -0.325435,
      0.005776,
      0.06341
    ],
    [
      -0.356352,
      0.01034,
      0.071244
    ],
    [
      -0.356352,
      0.01034,
      0.071244
    ],
    [
      -0.37566,
      0.01034,
      0.074164
    ],
    [
      -0.374097,
      0.005776,
      0.074055
    ],
    [
      -0.374097,
      0.005776,
      0.074055
    ],
    [
      -0.356694,
      0.005776,
      0.071278
    ],
    [
      -0.356352,
      0.01034,
      0.071244
    ],
    [
      -0.37566,
      0.01034,
      0.074164
    ],
    [
      -0.393273,
      0.01034,
      0.073482
    ],
    [
      -0.391595,
      0.005776,
      0.073713
    ],
    [
      -0.391595,
      0.005776,
      0.073713
    ],
    [
      -0.374097,
      0.005776,
      0.074055
    ],
    [
      -0.37566,
      0.01034,
      0.074164
    ],
    [
      -0.393273,
      0.01034,
      0.073482
    ],
    [
      -0.413243,
      0.01034,
      0.068132
    ],
    [
      -0.411487,
      0.005776,
      0.068814
    ],
    [
      -0.411487,
      0.005776,
      0.068814
    ],
    [
      -0.391595,
      0.005776,
      0.073713
    ],
    [
      -0.393273,
      0.01034,
      0.073482
    ],
    [
      -0.413243,
      0.01034,
      0.068132
    ],
    [
      -0.432853,
      0.01034,
      0.057205
    ],
    [
      -0.431135,
      0.005776,
      0.058448
    ],
    [
      -0.431135,
      0.005776,
      0.058448
    ],
    [
      -0.411487,
      0.005776,
      0.068814
    ],
    [
      -0.413243,
      0.01034,
      0.068132
    ],
    [
      -0.432853,
      0.01034,
      0.057205
    ],
    [
      -0.445796,
      0.01034,
      0.045282
    ],
    [
      -0.445796,
      0.005776,
      0.045282
    ],
    [
      -0.445796,
      0.005776,
      0.045282
    ],
    [
      -0.431135,
      0.005776,
      0.058448
    ],
    [
      -0.432853,
      0.01034,
      0.057205
    ],
    [
      -0.445796,
      0.01034,
      0.045282
    ],
    [
      -0.454477,
      0.01034,
      0.03447
    ],
    [
      -0.454477,
      0.005776,
      0.03447
    ],
    [
      -0.445796,
      0.005776,
      0.045282
    ],
    [
      -0.454477,
      0.01034,
      0.03447
    ],
    [
      -0.462395,
      0.01034,
      0.021487
    ],
    [
      -0.462395,
      0.005776,
      0.021487
    ],
    [
      -0.454477,
      0.005776,
      0.03447
    ],
    [
      -0.462395,
      0.01034,
      0.021487
    ],
    [
      -0.469368,
      0.01034,
      0.006164
    ],
    [
      -0.469368,
      0.005776,
      0.006164
    ],
    [
      -0.462395,
      0.005776,
      0.021487
    ],
    [
      -0.469368,
      0.01034,
      0.006164
    ],
    [
      -0.475214,
      0.01034,
      -0.011666
    ],
    [
      -0.475214,
      0.005776,
      -0.011666
    ],
    [
      -0.469368,
      0.005776,
      0.006164
    ],
    [
      -0.475214,
      0.01034,
      -0.011666
    ],
    [
      -0.479752,
      0.01034,
      -0.032173
    ],
    [
      -0.481118,
      0.005776,
      -0.039548
    ],
    [
      -0.481118,
      0.005776,
      -0.039548
    ],
    [
      -0.475214,
      0.005776,
      -0.011666
    ],
    [
      -0.475214,
      0.01034,
      -0.011666
    ],
    [
      -0.479752,
      0.01034,
      -0.032173
    ],
    [
      -0.482801,
      0.01034,
      -0.055524
    ],
    [
      -0.481118,
      0.005776,
      -0.039548
    ],
    [
      -0.482801,
      0.01034,
      -0.055524
    ],
    [
      -0.484532,
      0.01034,
      -0.091258
    ],
    [
      -0.484558,
      0.005776,
      -0.087892
    ],
    [
      -0.484558,
      0.005776,
      -0.087892
    ],
    [
      -0.481118,
      0.005776,
      -0.039548
    ],
    [
      -0.482801,
      0.01034,
      -0.055524
    ],
    [
      -0.484532,
      0.01034,
      -0.091258
    ],
    [
      -0.481715,
      0.01034,
      -0.123006
    ],
    [
      -0.48293,
      0.005776,
      -0.114207
    ],
    [
      -0.48293,
      0.005776,
      -0.114207
    ],
    [
      -0.484558,
      0.005776,
      -0.087892
    ],
    [
      -0.484532,
      0.01034,
      -0.091258
    ],
    [
      -0.479416,
      0.005776,
      -0.132777
    ],
    [
      -0.48293,
      0.005776,
      -0.114207
    ],
    [
      -0.481715,
      0.01034,
      -0.123006
    ],
    [
      -0.481715,
      0.01034,
      -0.123006
    ],
    [
      -0.475298,
      0.01034,
      -0.143894
    ],
    [
      -0.479416,
      0.005776,
      -0.132777
    ],
    [
      -0.472048,
      0.005776,
      -0.149895
    ],
    [
      -0.479416,
      0.005776,
      -0.132777
    ],
    [
      -0.475298,
      0.01034,
      -0.143894
    ],
    [
      -0.475298,
      0.01034,
      -0.143894
    ],
    [
      -0.46705,
      0.01034,
      -0.15647
    ],
    [
      -0.472048,
      0.005776,
      -0.149895
    ],
    [
      -0.46355,
      0.005776,
      -0.159807
    ],
    [
      -0.472048,
      0.005776,
      -0.149895
    ],
    [
      -0.46705,
      0.01034,
      -0.15647
    ],
    [
      -0.46705,
      0.01034,
      -0.15647
    ],
    [
      -0.458791,
      0.01034,
      -0.163235
    ],
    [
      -0.46355,
      0.005776,
      -0.159807
    ],
    [
      -0.455752,
      0.005776,
      -0.164885
    ],
    [
      -0.46355,
      0.005776,
      -0.159807
    ],
    [
      -0.458791,
      0.01034,
      -0.163235
    ],
    [
      -0.458791,
      0.01034,
      -0.163235
    ],
    [
      -0.442887,
      0.01034,
      -0.171112
    ],
    [
      -0.455752,
      0.005776,
      -0.164885
    ],
    [
      -0.427465,
      0.005776,
      -0.179706
    ],
    [
      -0.455752,
      0.005776,
      -0.164885
    ],
    [
      -0.442887,
      0.01034,
      -0.171112
    ]
  ],
  cells: [
    [
      0,
      1,
      2
    ],
    [
      0,
      3,
      1
    ],
    [
      4,
      3,
      0
    ],
    [
      4,
      5,
      3
    ],
    [
      6,
      5,
      4
    ],
    [
      6,
      7,
      5
    ],
    [
      8,
      7,
      6
    ],
    [
      8,
      9,
      7
    ],
    [
      10,
      9,
      8
    ],
    [
      10,
      11,
      9
    ],
    [
      10,
      12,
      11
    ],
    [
      13,
      12,
      10
    ],
    [
      13,
      14,
      12
    ],
    [
      15,
      14,
      13
    ],
    [
      15,
      16,
      14
    ],
    [
      17,
      16,
      15
    ],
    [
      17,
      18,
      16
    ],
    [
      17,
      19,
      18
    ],
    [
      17,
      20,
      19
    ],
    [
      21,
      20,
      17
    ],
    [
      21,
      22,
      20
    ],
    [
      21,
      23,
      22
    ],
    [
      24,
      23,
      21
    ],
    [
      24,
      25,
      23
    ],
    [
      24,
      26,
      25
    ],
    [
      27,
      26,
      24
    ],
    [
      27,
      28,
      26
    ],
    [
      27,
      29,
      28
    ],
    [
      27,
      30,
      29
    ],
    [
      27,
      31,
      30
    ],
    [
      32,
      31,
      27
    ],
    [
      32,
      33,
      31
    ],
    [
      32,
      34,
      33
    ],
    [
      32,
      35,
      34
    ],
    [
      36,
      35,
      32
    ],
    [
      36,
      37,
      35
    ],
    [
      38,
      37,
      36
    ],
    [
      38,
      39,
      37
    ],
    [
      40,
      39,
      38
    ],
    [
      40,
      41,
      39
    ],
    [
      42,
      41,
      40
    ],
    [
      43,
      41,
      42
    ],
    [
      43,
      44,
      41
    ],
    [
      45,
      44,
      43
    ],
    [
      45,
      46,
      44
    ],
    [
      45,
      47,
      46
    ],
    [
      48,
      47,
      45
    ],
    [
      49,
      47,
      48
    ],
    [
      50,
      47,
      49
    ],
    [
      50,
      51,
      47
    ],
    [
      52,
      51,
      50
    ],
    [
      52,
      53,
      51
    ],
    [
      54,
      53,
      52
    ],
    [
      54,
      55,
      53
    ],
    [
      56,
      55,
      54
    ],
    [
      56,
      57,
      55
    ],
    [
      58,
      57,
      56
    ],
    [
      58,
      59,
      57
    ],
    [
      60,
      59,
      58
    ],
    [
      60,
      61,
      59
    ],
    [
      62,
      61,
      60
    ],
    [
      62,
      63,
      61
    ],
    [
      62,
      64,
      63
    ],
    [
      65,
      64,
      62
    ],
    [
      65,
      66,
      64
    ],
    [
      67,
      66,
      65
    ],
    [
      67,
      68,
      66
    ],
    [
      67,
      69,
      68
    ],
    [
      70,
      69,
      67
    ],
    [
      70,
      71,
      69
    ],
    [
      70,
      72,
      71
    ],
    [
      73,
      72,
      70
    ],
    [
      73,
      74,
      72
    ],
    [
      75,
      74,
      73
    ],
    [
      75,
      76,
      74
    ],
    [
      75,
      77,
      76
    ],
    [
      78,
      77,
      75
    ],
    [
      79,
      77,
      78
    ],
    [
      79,
      80,
      77
    ],
    [
      81,
      80,
      79
    ],
    [
      82,
      80,
      81
    ],
    [
      83,
      84,
      82
    ],
    [
      84,
      80,
      82
    ],
    [
      84,
      85,
      80
    ],
    [
      83,
      86,
      84
    ],
    [
      87,
      85,
      84
    ],
    [
      83,
      88,
      86
    ],
    [
      89,
      85,
      87
    ],
    [
      90,
      88,
      83
    ],
    [
      90,
      91,
      88
    ],
    [
      92,
      85,
      89
    ],
    [
      90,
      93,
      91
    ],
    [
      90,
      94,
      93
    ],
    [
      95,
      85,
      92
    ],
    [
      96,
      94,
      90
    ],
    [
      97,
      85,
      95
    ],
    [
      96,
      98,
      94
    ],
    [
      99,
      98,
      96
    ],
    [
      99,
      100,
      98
    ],
    [
      101,
      100,
      99
    ],
    [
      101,
      102,
      100
    ],
    [
      103,
      85,
      97
    ],
    [
      104,
      85,
      103
    ],
    [
      105,
      85,
      104
    ],
    [
      105,
      106,
      85
    ],
    [
      107,
      106,
      105
    ],
    [
      108,
      106,
      107
    ],
    [
      108,
      109,
      106
    ],
    [
      110,
      109,
      108
    ],
    [
      110,
      111,
      109
    ],
    [
      112,
      111,
      110
    ],
    [
      112,
      113,
      111
    ],
    [
      114,
      113,
      112
    ],
    [
      114,
      115,
      113
    ],
    [
      116,
      117,
      114
    ],
    [
      117,
      115,
      114
    ],
    [
      116,
      118,
      117
    ],
    [
      119,
      115,
      117
    ],
    [
      120,
      115,
      119
    ],
    [
      116,
      121,
      118
    ],
    [
      120,
      122,
      115
    ],
    [
      123,
      122,
      120
    ],
    [
      116,
      124,
      121
    ],
    [
      116,
      125,
      124
    ],
    [
      126,
      125,
      116
    ],
    [
      127,
      122,
      123
    ],
    [
      126,
      128,
      125
    ],
    [
      126,
      129,
      128
    ],
    [
      127,
      130,
      122
    ],
    [
      131,
      130,
      127
    ],
    [
      132,
      129,
      126
    ],
    [
      132,
      133,
      129
    ],
    [
      134,
      130,
      131
    ],
    [
      134,
      135,
      130
    ],
    [
      136,
      133,
      132
    ],
    [
      136,
      137,
      133
    ],
    [
      138,
      135,
      134
    ],
    [
      136,
      139,
      137
    ],
    [
      140,
      139,
      136
    ],
    [
      140,
      141,
      139
    ],
    [
      138,
      142,
      135
    ],
    [
      140,
      143,
      141
    ],
    [
      144,
      143,
      140
    ],
    [
      145,
      142,
      138
    ],
    [
      144,
      146,
      143
    ],
    [
      147,
      146,
      144
    ],
    [
      145,
      148,
      142
    ],
    [
      149,
      148,
      145
    ],
    [
      149,
      150,
      148
    ],
    [
      149,
      151,
      150
    ],
    [
      152,
      151,
      149
    ],
    [
      152,
      153,
      151
    ],
    [
      154,
      153,
      152
    ],
    [
      154,
      155,
      153
    ],
    [
      156,
      155,
      154
    ],
    [
      156,
      157,
      155
    ],
    [
      158,
      157,
      156
    ],
    [
      158,
      159,
      157
    ],
    [
      160,
      159,
      158
    ],
    [
      160,
      161,
      159
    ],
    [
      162,
      161,
      160
    ],
    [
      162,
      163,
      161
    ],
    [
      164,
      163,
      162
    ],
    [
      164,
      165,
      163
    ],
    [
      166,
      165,
      164
    ],
    [
      166,
      167,
      165
    ],
    [
      168,
      167,
      166
    ],
    [
      168,
      169,
      167
    ],
    [
      170,
      169,
      168
    ],
    [
      170,
      171,
      169
    ],
    [
      172,
      171,
      170
    ],
    [
      172,
      173,
      171
    ],
    [
      172,
      174,
      173
    ],
    [
      175,
      174,
      172
    ],
    [
      176,
      177,
      178
    ],
    [
      179,
      177,
      176
    ],
    [
      179,
      180,
      177
    ],
    [
      181,
      180,
      179
    ],
    [
      181,
      182,
      180
    ],
    [
      183,
      182,
      181
    ],
    [
      183,
      184,
      182
    ],
    [
      185,
      184,
      183
    ],
    [
      185,
      186,
      184
    ],
    [
      187,
      186,
      185
    ],
    [
      188,
      186,
      187
    ],
    [
      188,
      189,
      186
    ],
    [
      190,
      189,
      188
    ],
    [
      190,
      191,
      189
    ],
    [
      192,
      191,
      190
    ],
    [
      192,
      193,
      191
    ],
    [
      194,
      193,
      192
    ],
    [
      195,
      193,
      194
    ],
    [
      196,
      193,
      195
    ],
    [
      196,
      197,
      193
    ],
    [
      198,
      197,
      196
    ],
    [
      199,
      197,
      198
    ],
    [
      199,
      200,
      197
    ],
    [
      201,
      200,
      199
    ],
    [
      202,
      200,
      201
    ],
    [
      202,
      203,
      200
    ],
    [
      204,
      203,
      202
    ],
    [
      205,
      203,
      204
    ],
    [
      206,
      203,
      205
    ],
    [
      207,
      203,
      206
    ],
    [
      207,
      208,
      203
    ],
    [
      209,
      208,
      207
    ],
    [
      210,
      208,
      209
    ],
    [
      211,
      208,
      210
    ],
    [
      211,
      212,
      208
    ],
    [
      213,
      212,
      211
    ],
    [
      213,
      214,
      212
    ],
    [
      215,
      214,
      213
    ],
    [
      215,
      216,
      214
    ],
    [
      217,
      216,
      215
    ],
    [
      217,
      218,
      216
    ],
    [
      217,
      219,
      218
    ],
    [
      220,
      219,
      217
    ],
    [
      220,
      221,
      219
    ],
    [
      222,
      221,
      220
    ],
    [
      223,
      221,
      222
    ],
    [
      223,
      224,
      221
    ],
    [
      223,
      225,
      224
    ],
    [
      223,
      226,
      225
    ],
    [
      227,
      226,
      223
    ],
    [
      227,
      228,
      226
    ],
    [
      229,
      228,
      227
    ],
    [
      229,
      230,
      228
    ],
    [
      231,
      230,
      229
    ],
    [
      231,
      232,
      230
    ],
    [
      233,
      232,
      231
    ],
    [
      233,
      234,
      232
    ],
    [
      235,
      234,
      233
    ],
    [
      235,
      236,
      234
    ],
    [
      237,
      236,
      235
    ],
    [
      237,
      238,
      236
    ],
    [
      239,
      238,
      237
    ],
    [
      240,
      238,
      239
    ],
    [
      240,
      241,
      238
    ],
    [
      242,
      241,
      240
    ],
    [
      242,
      243,
      241
    ],
    [
      244,
      243,
      242
    ],
    [
      245,
      243,
      244
    ],
    [
      245,
      246,
      243
    ],
    [
      247,
      246,
      245
    ],
    [
      248,
      246,
      247
    ],
    [
      248,
      249,
      246
    ],
    [
      250,
      249,
      248
    ],
    [
      250,
      251,
      249
    ],
    [
      252,
      251,
      250
    ],
    [
      253,
      251,
      252
    ],
    [
      253,
      254,
      251
    ],
    [
      253,
      255,
      254
    ],
    [
      256,
      255,
      253
    ],
    [
      256,
      257,
      255
    ],
    [
      256,
      258,
      257
    ],
    [
      259,
      260,
      258
    ],
    [
      256,
      259,
      258
    ],
    [
      261,
      259,
      256
    ],
    [
      262,
      260,
      259
    ],
    [
      261,
      263,
      259
    ],
    [
      264,
      260,
      262
    ],
    [
      261,
      265,
      263
    ],
    [
      264,
      266,
      260
    ],
    [
      267,
      266,
      264
    ],
    [
      261,
      268,
      265
    ],
    [
      269,
      266,
      267
    ],
    [
      270,
      266,
      269
    ],
    [
      261,
      271,
      268
    ],
    [
      270,
      272,
      266
    ],
    [
      261,
      273,
      271
    ],
    [
      274,
      272,
      270
    ],
    [
      274,
      275,
      272
    ],
    [
      276,
      275,
      274
    ],
    [
      276,
      277,
      275
    ],
    [
      278,
      277,
      276
    ],
    [
      261,
      279,
      273
    ],
    [
      261,
      280,
      279
    ],
    [
      261,
      281,
      280
    ],
    [
      282,
      281,
      261
    ],
    [
      282,
      283,
      281
    ],
    [
      282,
      284,
      283
    ],
    [
      285,
      284,
      282
    ],
    [
      285,
      286,
      284
    ],
    [
      287,
      286,
      285
    ],
    [
      287,
      288,
      286
    ],
    [
      289,
      288,
      287
    ],
    [
      289,
      290,
      288
    ],
    [
      291,
      290,
      289
    ],
    [
      292,
      293,
      290
    ],
    [
      291,
      292,
      290
    ],
    [
      294,
      293,
      292
    ],
    [
      291,
      295,
      292
    ],
    [
      291,
      296,
      295
    ],
    [
      297,
      293,
      294
    ],
    [
      298,
      296,
      291
    ],
    [
      298,
      299,
      296
    ],
    [
      300,
      293,
      297
    ],
    [
      301,
      293,
      300
    ],
    [
      301,
      302,
      293
    ],
    [
      298,
      303,
      299
    ],
    [
      304,
      302,
      301
    ],
    [
      305,
      302,
      304
    ],
    [
      306,
      303,
      298
    ],
    [
      306,
      307,
      303
    ],
    [
      305,
      308,
      302
    ],
    [
      309,
      308,
      305
    ],
    [
      306,
      310,
      307
    ],
    [
      311,
      310,
      306
    ],
    [
      309,
      312,
      308
    ],
    [
      313,
      312,
      309
    ],
    [
      311,
      314,
      310
    ],
    [
      315,
      312,
      313
    ],
    [
      315,
      316,
      312
    ],
    [
      317,
      316,
      315
    ],
    [
      318,
      314,
      311
    ],
    [
      319,
      316,
      317
    ],
    [
      319,
      320,
      316
    ],
    [
      318,
      321,
      314
    ],
    [
      322,
      320,
      319
    ],
    [
      322,
      323,
      320
    ],
    [
      324,
      321,
      318
    ],
    [
      324,
      325,
      321
    ],
    [
      326,
      325,
      324
    ],
    [
      327,
      325,
      326
    ],
    [
      327,
      328,
      325
    ],
    [
      329,
      328,
      327
    ],
    [
      329,
      330,
      328
    ],
    [
      331,
      330,
      329
    ],
    [
      331,
      332,
      330
    ],
    [
      333,
      332,
      331
    ],
    [
      333,
      334,
      332
    ],
    [
      335,
      334,
      333
    ],
    [
      335,
      336,
      334
    ],
    [
      337,
      336,
      335
    ],
    [
      337,
      338,
      336
    ],
    [
      339,
      338,
      337
    ],
    [
      339,
      340,
      338
    ],
    [
      341,
      340,
      339
    ],
    [
      341,
      342,
      340
    ],
    [
      343,
      342,
      341
    ],
    [
      343,
      344,
      342
    ],
    [
      345,
      344,
      343
    ],
    [
      345,
      346,
      344
    ],
    [
      347,
      346,
      345
    ],
    [
      347,
      348,
      346
    ],
    [
      349,
      348,
      347
    ],
    [
      350,
      348,
      349
    ],
    [
      350,
      351,
      348
    ],
    [
      352,
      353,
      354
    ],
    [
      355,
      356,
      357
    ],
    [
      358,
      359,
      360
    ],
    [
      361,
      362,
      363
    ],
    [
      364,
      365,
      366
    ],
    [
      367,
      368,
      369
    ],
    [
      370,
      371,
      372
    ],
    [
      373,
      374,
      375
    ],
    [
      376,
      377,
      378
    ],
    [
      379,
      380,
      381
    ],
    [
      382,
      383,
      384
    ],
    [
      384,
      385,
      382
    ],
    [
      386,
      387,
      388
    ],
    [
      388,
      389,
      386
    ],
    [
      390,
      391,
      392
    ],
    [
      392,
      393,
      390
    ],
    [
      394,
      395,
      396
    ],
    [
      396,
      397,
      394
    ],
    [
      398,
      399,
      400
    ],
    [
      401,
      402,
      403
    ],
    [
      404,
      405,
      406
    ],
    [
      407,
      408,
      409
    ],
    [
      410,
      411,
      412
    ],
    [
      413,
      414,
      415
    ],
    [
      416,
      417,
      418
    ],
    [
      419,
      420,
      421
    ],
    [
      422,
      423,
      424
    ],
    [
      425,
      426,
      427
    ],
    [
      428,
      429,
      430
    ],
    [
      431,
      432,
      433
    ],
    [
      434,
      435,
      436
    ],
    [
      437,
      438,
      439
    ],
    [
      440,
      441,
      442
    ],
    [
      443,
      444,
      445
    ],
    [
      446,
      447,
      448
    ],
    [
      449,
      450,
      451
    ],
    [
      452,
      453,
      454
    ],
    [
      455,
      456,
      457
    ],
    [
      458,
      459,
      460
    ],
    [
      461,
      462,
      463
    ],
    [
      464,
      465,
      466
    ],
    [
      467,
      468,
      469
    ],
    [
      470,
      471,
      472
    ],
    [
      472,
      473,
      470
    ],
    [
      474,
      475,
      476
    ],
    [
      476,
      477,
      474
    ],
    [
      478,
      479,
      480
    ],
    [
      481,
      482,
      483
    ],
    [
      484,
      485,
      486
    ],
    [
      487,
      488,
      489
    ],
    [
      490,
      491,
      492
    ],
    [
      493,
      494,
      495
    ],
    [
      496,
      497,
      498
    ],
    [
      499,
      500,
      501
    ],
    [
      502,
      503,
      504
    ],
    [
      505,
      506,
      507
    ],
    [
      508,
      509,
      510
    ],
    [
      511,
      512,
      513
    ],
    [
      514,
      515,
      516
    ],
    [
      517,
      518,
      519
    ],
    [
      520,
      521,
      522
    ],
    [
      523,
      524,
      525
    ],
    [
      526,
      527,
      528
    ],
    [
      529,
      530,
      531
    ],
    [
      532,
      533,
      534
    ],
    [
      535,
      536,
      537
    ],
    [
      538,
      539,
      540
    ],
    [
      541,
      542,
      543
    ],
    [
      544,
      545,
      546
    ],
    [
      547,
      548,
      549
    ],
    [
      550,
      551,
      552
    ],
    [
      553,
      554,
      555
    ],
    [
      556,
      557,
      558
    ],
    [
      559,
      560,
      561
    ],
    [
      562,
      563,
      564
    ],
    [
      565,
      566,
      567
    ],
    [
      568,
      569,
      570
    ],
    [
      571,
      572,
      573
    ],
    [
      574,
      575,
      576
    ],
    [
      577,
      578,
      579
    ],
    [
      580,
      581,
      582
    ],
    [
      583,
      584,
      585
    ],
    [
      586,
      587,
      588
    ],
    [
      589,
      590,
      591
    ],
    [
      592,
      593,
      594
    ],
    [
      595,
      596,
      597
    ],
    [
      598,
      599,
      600
    ],
    [
      601,
      602,
      603
    ],
    [
      604,
      605,
      606
    ],
    [
      607,
      608,
      609
    ],
    [
      610,
      611,
      612
    ],
    [
      613,
      614,
      615
    ],
    [
      616,
      617,
      618
    ],
    [
      619,
      620,
      621
    ],
    [
      622,
      623,
      624
    ],
    [
      625,
      626,
      627
    ],
    [
      628,
      629,
      630
    ],
    [
      631,
      632,
      633
    ],
    [
      634,
      635,
      636
    ],
    [
      637,
      638,
      639
    ],
    [
      640,
      641,
      642
    ],
    [
      643,
      644,
      645
    ],
    [
      646,
      647,
      648
    ],
    [
      649,
      650,
      651
    ],
    [
      652,
      653,
      654
    ],
    [
      654,
      655,
      652
    ],
    [
      656,
      657,
      658
    ],
    [
      658,
      659,
      656
    ],
    [
      660,
      661,
      662
    ],
    [
      663,
      664,
      665
    ],
    [
      666,
      667,
      668
    ],
    [
      669,
      670,
      671
    ],
    [
      672,
      673,
      674
    ],
    [
      675,
      676,
      677
    ],
    [
      678,
      679,
      680
    ],
    [
      681,
      682,
      683
    ],
    [
      684,
      685,
      686
    ],
    [
      687,
      688,
      689
    ],
    [
      690,
      691,
      692
    ],
    [
      693,
      694,
      695
    ],
    [
      696,
      697,
      698
    ],
    [
      698,
      699,
      696
    ],
    [
      700,
      701,
      702
    ],
    [
      702,
      703,
      700
    ],
    [
      704,
      705,
      706
    ],
    [
      706,
      707,
      704
    ],
    [
      708,
      709,
      710
    ],
    [
      710,
      711,
      708
    ],
    [
      712,
      713,
      714
    ],
    [
      714,
      715,
      712
    ],
    [
      716,
      717,
      718
    ],
    [
      719,
      720,
      721
    ],
    [
      722,
      723,
      724
    ],
    [
      725,
      726,
      727
    ],
    [
      728,
      729,
      730
    ],
    [
      731,
      732,
      733
    ],
    [
      734,
      735,
      736
    ],
    [
      737,
      738,
      739
    ],
    [
      740,
      741,
      742
    ],
    [
      743,
      744,
      745
    ],
    [
      746,
      747,
      748
    ],
    [
      749,
      750,
      751
    ],
    [
      752,
      753,
      754
    ],
    [
      755,
      756,
      757
    ],
    [
      758,
      759,
      760
    ],
    [
      761,
      762,
      763
    ],
    [
      764,
      765,
      766
    ],
    [
      767,
      768,
      769
    ],
    [
      770,
      771,
      772
    ],
    [
      773,
      774,
      775
    ],
    [
      776,
      777,
      778
    ],
    [
      779,
      780,
      781
    ],
    [
      782,
      783,
      784
    ],
    [
      785,
      786,
      787
    ],
    [
      788,
      789,
      790
    ],
    [
      791,
      792,
      793
    ],
    [
      794,
      795,
      796
    ],
    [
      797,
      798,
      799
    ],
    [
      800,
      801,
      802
    ],
    [
      803,
      804,
      805
    ],
    [
      806,
      807,
      808
    ],
    [
      809,
      810,
      811
    ],
    [
      812,
      813,
      814
    ],
    [
      814,
      815,
      812
    ],
    [
      816,
      817,
      818
    ],
    [
      818,
      819,
      816
    ],
    [
      820,
      821,
      822
    ],
    [
      822,
      823,
      820
    ],
    [
      824,
      825,
      826
    ],
    [
      826,
      827,
      824
    ],
    [
      828,
      829,
      830
    ],
    [
      831,
      832,
      833
    ],
    [
      834,
      835,
      836
    ],
    [
      837,
      838,
      839
    ],
    [
      840,
      841,
      842
    ],
    [
      843,
      844,
      845
    ],
    [
      846,
      847,
      848
    ],
    [
      849,
      850,
      851
    ],
    [
      852,
      853,
      854
    ],
    [
      855,
      856,
      857
    ],
    [
      858,
      859,
      860
    ],
    [
      861,
      862,
      863
    ],
    [
      864,
      865,
      866
    ],
    [
      867,
      868,
      869
    ],
    [
      870,
      871,
      872
    ],
    [
      873,
      874,
      875
    ]
  ]
};
