const blobMesh = {
    positions: [
        [-0.018803, -0.03606, 0.001025],
        [-0.017162, -0.036438, 0.001027],
        [-0.015486, -0.036604, 0.001027],
        [-0.013801, -0.036556, 0.001027],
        [-0.012132, -0.036319, 0.001026],
        [-0.010494, -0.035915, 0.001025],
        [-0.008898, -0.03537, 0.001023],
        [-0.007349, -0.034701, 0.001021],
        [-0.00585, -0.033925, 0.001018],
        [-0.004401, -0.033058, 0.001015],
        [-0.002996, -0.032122, 0.001012],
        [-0.001626, -0.031135, 0.001008],
        [-0.000549, -0.030312, 0.001006],
        [0.000639, -0.029391, 0.001002],
        [0.00197, -0.028352, 0.000999],
        [0.003306, -0.027318, 0.000995],
        [0.004658, -0.026306, 0.000992],
        [0.006035, -0.02533, 0.000988],
        [0.007439, -0.024393, 0.000985],
        [0.008864, -0.023488, 0.000982],
        [0.010308, -0.022612, 0.000979],
        [0.011766, -0.021759, 0.000976],
        [0.013234, -0.020925, 0.000973],
        [0.014712, -0.020108, 0.00097],
        [0.016197, -0.019303, 0.000967],
        [0.017687, -0.01851, 0.000965],
        [0.019181, -0.017724, 0.000962],
        [0.020678, -0.016944, 0.000959],
        [0.022176, -0.016169, 0.000957],
        [0.023676, -0.015395, 0.000954],
        [0.025174, -0.01462, 0.000951],
        [0.026672, -0.013844, 0.000948],
        [0.028167, -0.013063, 0.000946],
        [0.029659, -0.012275, 0.000943],
        [0.030704, -0.011676, 0.000941],
        [0.032157, -0.01089, 0.000938],
        [0.033635, -0.010078, 0.000935],
        [0.035107, -0.009253, 0.000933],
        [0.03657, -0.008413, 0.00093],
        [0.038024, -0.007555, 0.000927],
        [0.039466, -0.006677, 0.000924],
        [0.040894, -0.005777, 0.00092],
        [0.042307, -0.004853, 0.000917],
        [0.043702, -0.003901, 0.000914],
        [0.045076, -0.002919, 0.000911],
        [0.046426, -0.001904, 0.000907],
        [0.047749, -0.000855, 0.000903],
        [0.049039, 0.000233, 0.0009],
        [0.050293, 0.001363, 0.000896],
        [0.051502, 0.002538, 0.000892],
        [0.052657, 0.003765, 0.000887],
        [0.053744, 0.005052, 0.000883],
        [0.054743, 0.006404, 0.000878],
        [0.055631, 0.00783, 0.000873],
        [0.056375, 0.009333, 0.000868],
        [0.056912, 0.010802, 0.000863],
        [0.057229, 0.012044, 0.000859],
        [0.057315, 0.013728, 0.000853],
        [0.057059, 0.015393, 0.000847],
        [0.056494, 0.01698, 0.000842],
        [0.055657, 0.018442, 0.000837],
        [0.054576, 0.019734, 0.000832],
        [0.053304, 0.020841, 0.000828],
        [0.051897, 0.02177, 0.000825],
        [0.050396, 0.022541, 0.000822],
        [0.048833, 0.023175, 0.00082],
        [0.047229, 0.023697, 0.000818],
        [0.045597, 0.024124, 0.000817],
        [0.043946, 0.02447, 0.000816],
        [0.042281, 0.024744, 0.000815],
        [0.040607, 0.024954, 0.000814],
        [0.038926, 0.025108, 0.000813],
        [0.037242, 0.025211, 0.000813],
        [0.035555, 0.02527, 0.000813],
        [0.033867, 0.025289, 0.000813],
        [0.032179, 0.025273, 0.000813],
        [0.030491, 0.025227, 0.000813],
        [0.029065, 0.025153, 0.000813],
        [0.027638, 0.025061, 0.000814],
        [0.025954, 0.024948, 0.000814],
        [0.024272, 0.024815, 0.000814],
        [0.022591, 0.024667, 0.000815],
        [0.020911, 0.024504, 0.000815],
        [0.019233, 0.024331, 0.000816],
        [0.017557, 0.024149, 0.000817],
        [0.015882, 0.023961, 0.000817],
        [0.014209, 0.02377, 0.000818],
        [0.012537, 0.023579, 0.000819],
        [0.010867, 0.023389, 0.000819],
        [0.009198, 0.023204, 0.00082],
        [0.007532, 0.023014, 0.000821],
        [0.005893, 0.022725, 0.000822],
        [0.004289, 0.022339, 0.000823],
        [0.002714, 0.021887, 0.000825],
        [0.001157, 0.021397, 0.000826],
        [-0.000396, 0.020897, 0.000828],
        [-0.00196, 0.020411, 0.00083],
        [-0.003547, 0.019968, 0.000831],
        [-0.005168, 0.019598, 0.000832],
        [-0.006413, 0.019328, 0.000833],
        [-0.007987, 0.019217, 0.000834],
        [-0.009674, 0.019264, 0.000834],
        [-0.011348, 0.019424, 0.000833],
        [-0.013006, 0.019645, 0.000832],
        [-0.01465, 0.019891, 0.000831],
        [-0.016287, 0.020134, 0.000831],
        [-0.017919, 0.020353, 0.00083],
        [-0.01955, 0.020523, 0.000829],
        [-0.021185, 0.020621, 0.000829],
        [-0.022822, 0.020614, 0.000829],
        [-0.024458, 0.020462, 0.00083],
        [-0.026074, 0.020107, 0.000831],
        [-0.027625, 0.019488, 0.000833],
        [-0.029057, 0.018603, 0.000836],
        [-0.030417, 0.017609, 0.000839],
        [-0.031707, 0.016526, 0.000843],
        [-0.032916, 0.015354, 0.000847],
        [-0.034033, 0.014093, 0.000852],
        [-0.035046, 0.012747, 0.000856],
        [-0.035947, 0.011323, 0.000861],
        [-0.036711, 0.009866, 0.000866],
        [-0.037211, 0.008763, 0.00087],
        [-0.037775, 0.007172, 0.000876],
        [-0.038214, 0.005541, 0.000881],
        [-0.03853, 0.003883, 0.000887],
        [-0.03872, 0.002206, 0.000893],
        [-0.038786, 0.000519, 0.000899],
        [-0.038729, -0.001169, 0.000905],
        [-0.038552, -0.002848, 0.00091],
        [-0.038256, -0.00451, 0.000916],
        [-0.037844, -0.006147, 0.000922],
        [-0.037318, -0.007751, 0.000927],
        [-0.036678, -0.009314, 0.000933],
        [-0.035928, -0.010826, 0.000938],
        [-0.035069, -0.01228, 0.000943],
        [-0.034103, -0.013664, 0.000948],
        [-0.033045, -0.01498, 0.000952],
        [-0.031938, -0.016256, 0.000957],
        [-0.03083, -0.01753, 0.000961],
        [-0.029764, -0.01884, 0.000966],
        [-0.028791, -0.02022, 0.000971],
        [-0.027974, -0.021696, 0.000976],
        [-0.027441, -0.023095, 0.000981],
        [-0.02709, -0.024407, 0.000985],
        [-0.026728, -0.026056, 0.000991],
        [-0.026338, -0.027699, 0.000996],
        [-0.025806, -0.029298, 0.001002],
        [-0.025094, -0.030826, 0.001007],
        [-0.024195, -0.03225, 0.001012],
        [-0.023103, -0.033532, 0.001017],
        [-0.021822, -0.034622, 0.00102],
        [-0.020373, -0.035473, 0.001023],
    ],
    cells: [
        [1, 3, 2],
        [1, 4, 3],
        [0, 4, 1],
        [0, 5, 4],
        [151, 5, 0],
        [151, 6, 5],
        [150, 6, 151],
        [150, 7, 6],
        [150, 8, 7],
        [149, 8, 150],
        [149, 9, 8],
        [148, 9, 149],
        [148, 10, 9],
        [147, 10, 148],
        [147, 11, 10],
        [147, 12, 11],
        [146, 12, 147],
        [146, 13, 12],
        [146, 14, 13],
        [145, 14, 146],
        [145, 15, 14],
        [144, 15, 145],
        [144, 16, 15],
        [144, 17, 16],
        [143, 17, 144],
        [143, 18, 17],
        [142, 18, 143],
        [142, 19, 18],
        [142, 20, 19],
        [141, 20, 142],
        [141, 21, 20],
        [141, 22, 21],
        [140, 22, 141],
        [140, 23, 22],
        [139, 23, 140],
        [139, 24, 23],
        [139, 25, 24],
        [138, 25, 139],
        [138, 26, 25],
        [138, 27, 26],
        [137, 27, 138],
        [137, 28, 27],
        [136, 28, 137],
        [136, 29, 28],
        [136, 30, 29],
        [135, 30, 136],
        [135, 31, 30],
        [135, 32, 31],
        [134, 32, 135],
        [134, 33, 32],
        [133, 33, 134],
        [133, 34, 33],
        [133, 35, 34],
        [133, 36, 35],
        [132, 36, 133],
        [132, 37, 36],
        [131, 37, 132],
        [131, 38, 37],
        [131, 39, 38],
        [130, 39, 131],
        [130, 40, 39],
        [130, 41, 40],
        [129, 41, 130],
        [129, 42, 41],
        [129, 43, 42],
        [128, 43, 129],
        [128, 44, 43],
        [128, 45, 44],
        [127, 45, 128],
        [127, 46, 45],
        [126, 46, 127],
        [126, 47, 46],
        [126, 48, 47],
        [125, 48, 126],
        [125, 49, 48],
        [124, 49, 125],
        [124, 50, 49],
        [124, 51, 50],
        [123, 51, 124],
        [123, 52, 51],
        [122, 52, 123],
        [122, 53, 52],
        [121, 53, 122],
        [121, 54, 53],
        [120, 54, 121],
        [120, 55, 54],
        [119, 55, 120],
        [119, 56, 55],
        [118, 56, 119],
        [118, 57, 56],
        [117, 57, 118],
        [117, 58, 57],
        [116, 58, 117],
        [115, 58, 116],
        [115, 59, 58],
        [114, 59, 115],
        [114, 60, 59],
        [113, 60, 114],
        [113, 61, 60],
        [112, 100, 113],
        [100, 61, 113],
        [112, 101, 100],
        [99, 61, 100],
        [112, 102, 101],
        [98, 61, 99],
        [112, 103, 102],
        [111, 103, 112],
        [97, 61, 98],
        [111, 104, 103],
        [97, 62, 61],
        [111, 105, 104],
        [96, 62, 97],
        [110, 105, 111],
        [110, 106, 105],
        [110, 107, 106],
        [95, 62, 96],
        [109, 107, 110],
        [109, 108, 107],
        [95, 63, 62],
        [94, 63, 95],
        [93, 63, 94],
        [93, 64, 63],
        [92, 64, 93],
        [91, 64, 92],
        [91, 65, 64],
        [90, 65, 91],
        [89, 65, 90],
        [89, 66, 65],
        [88, 66, 89],
        [87, 66, 88],
        [86, 66, 87],
        [86, 67, 66],
        [85, 67, 86],
        [84, 67, 85],
        [84, 68, 67],
        [83, 68, 84],
        [82, 68, 83],
        [82, 69, 68],
        [81, 69, 82],
        [80, 69, 81],
        [80, 70, 69],
        [79, 70, 80],
        [78, 70, 79],
        [78, 71, 70],
        [77, 71, 78],
        [77, 72, 71],
        [76, 72, 77],
        [76, 73, 72],
        [75, 73, 76],
        [75, 74, 73],
    ],
    normals: [
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0034, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
        [0, -0.0035, -1],
    ],
};
export default blobMesh;
