
import * as React from "react";
import { mat4 } from 'gl-matrix';

import Blob from '../Blob';

import { degToRad } from '../../utils/graphics';

// import blobMeshPurple from '../Blob/meshes/blobMesh';
// import blobMeshPurple from '../Blob/meshes/blobMeshLowerRes50';
import blobMeshPurple from '../Blob/meshes/blobMeshLowerRes70';

// import blobMeshGreen from '../Blob/meshes/blobMesh2';
// import blobMeshGreen from '../Blob/meshes/blobMesh2LowerRes50';
// import blobMeshGreen from '../Blob/meshes/blobMesh2LowerRes30';
import blobMeshGreen from '../Blob/meshes/blobMesh2LowerRes70';

import useMediaQuery from '../../utils/useMediaQuery';
import st from './MenuBlobGroup.module.scss';

export interface MenuBlobGroupProps { }

const MenuBlobGroup: React.FC<MenuBlobGroupProps> = ({ }) => {
    let _ = mat4.create();

    const useSmallDeformation = useMediaQuery({
        query: `(max-width: 960px)`
    });

    const deformationScalePurple = useSmallDeformation
        ? [60, 60]
        : [45, 45];

    const deformationScaleGreen = useSmallDeformation
        ? [8, 8]
        : [5, 5];

    const rotationGreen = mat4.rotateX(_,
        mat4.rotateZ(_,
            mat4.create(), // create() returns id matrix
            degToRad(110)
        ),
        degToRad(-90)
    );

    return (
        <>
            <Blob
                color='#37f48d'
                mesh={blobMeshGreen}
                scale={27}
                deformationScale={deformationScaleGreen}
                magnitude={0.035}
                speed={0.008}
                rotation={rotationGreen}
                wrapperClassName={st.BlobWrapperGreen}
            />

            <Blob
                color='#250154'
                mesh={blobMeshPurple}
                scale={270}
                deformationScale={deformationScalePurple}
                magnitude={0.008}
                speed={0.004}
                rotation={mat4.create()}
                wrapperClassName={st.BlobWrapperPurple}
            />
        </>
    )
};

export default MenuBlobGroup
