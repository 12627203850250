export default {
    activeId: 2,
    items: [
        {
            title: 'Karriär',
            url: '#',
            linkPageId: 1,
        },
        {
            title: 'Kontakt',
            url: '#',
            linkPageId: 2,
        },
        {
            title: 'Luncha?',
            url: '#',
            linkPageId: 3,
        },
    ],
};
