export default {
  positions: [
    [
      -0.017162,
      -0.036438,
      0.001027
    ],
    [
      -0.013801,
      -0.036556,
      0.001027
    ],
    [
      -0.015486,
      -0.036604,
      0.001027
    ],
    [
      -0.012132,
      -0.036319,
      0.001026
    ],
    [
      -0.018803,
      -0.03606,
      0.001025
    ],
    [
      -0.010494,
      -0.035915,
      0.001025
    ],
    [
      -0.020373,
      -0.035473,
      0.001023
    ],
    [
      -0.008898,
      -0.03537,
      0.001023
    ],
    [
      -0.021822,
      -0.034622,
      0.00102
    ],
    [
      -0.007349,
      -0.034701,
      0.001021
    ],
    [
      -0.00585,
      -0.033925,
      0.001018
    ],
    [
      -0.023103,
      -0.033532,
      0.001017
    ],
    [
      -0.004401,
      -0.033058,
      0.001015
    ],
    [
      -0.024195,
      -0.03225,
      0.001012
    ],
    [
      -0.002996,
      -0.032122,
      0.001012
    ],
    [
      -0.025094,
      -0.030826,
      0.001007
    ],
    [
      -0.001341,
      -0.030925,
      0.001008
    ],
    [
      -0.025806,
      -0.029298,
      0.001002
    ],
    [
      0.003956,
      -0.026816,
      0.000993
    ],
    [
      -0.026381,
      -0.027559,
      0.000996
    ],
    [
      0.006619,
      -0.024927,
      0.000986
    ],
    [
      -0.02709,
      -0.024407,
      0.000985
    ],
    [
      -0.027441,
      -0.023095,
      0.000981
    ],
    [
      0.009447,
      -0.023126,
      0.000981
    ],
    [
      -0.027974,
      -0.021696,
      0.000976
    ],
    [
      0.012451,
      -0.021364,
      0.000975
    ],
    [
      -0.028791,
      -0.02022,
      0.000971
    ],
    [
      0.015414,
      -0.019724,
      0.000969
    ],
    [
      -0.029764,
      -0.01884,
      0.000966
    ],
    [
      0.019276,
      -0.017671,
      0.000962
    ],
    [
      -0.03083,
      -0.01753,
      0.000961
    ],
    [
      -0.033033,
      -0.014995,
      0.000952
    ],
    [
      0.029208,
      -0.012527,
      0.000944
    ],
    [
      -0.034103,
      -0.013664,
      0.000948
    ],
    [
      -0.035069,
      -0.01228,
      0.000943
    ],
    [
      -0.035928,
      -0.010826,
      0.000938
    ],
    [
      0.030704,
      -0.011676,
      0.000941
    ],
    [
      0.034739,
      -0.009472,
      0.000934
    ],
    [
      -0.036678,
      -0.009314,
      0.000933
    ],
    [
      -0.037318,
      -0.007751,
      0.000927
    ],
    [
      0.038808,
      -0.007085,
      0.000926
    ],
    [
      -0.037844,
      -0.006147,
      0.000922
    ],
    [
      0.04169,
      -0.005266,
      0.000918
    ],
    [
      -0.038256,
      -0.00451,
      0.000916
    ],
    [
      0.04445,
      -0.003378,
      0.000913
    ],
    [
      -0.038552,
      -0.002848,
      0.00091
    ],
    [
      0.047175,
      -0.001325,
      0.000905
    ],
    [
      -0.038729,
      -0.001169,
      0.000905
    ],
    [
      -0.038786,
      0.000519,
      0.000899
    ],
    [
      0.049742,
      0.000846,
      0.000898
    ],
    [
      -0.03872,
      0.002206,
      0.000893
    ],
    [
      0.051502,
      0.002538,
      0.000892
    ],
    [
      -0.03853,
      0.003883,
      0.000887
    ],
    [
      0.052657,
      0.003765,
      0.000887
    ],
    [
      0.053744,
      0.005052,
      0.000883
    ],
    [
      -0.038214,
      0.005541,
      0.000881
    ],
    [
      0.054743,
      0.006404,
      0.000878
    ],
    [
      -0.037775,
      0.007172,
      0.000876
    ],
    [
      0.055631,
      0.00783,
      0.000873
    ],
    [
      -0.037211,
      0.008763,
      0.00087
    ],
    [
      0.056375,
      0.009333,
      0.000868
    ],
    [
      -0.036711,
      0.009866,
      0.000866
    ],
    [
      0.056912,
      0.010802,
      0.000863
    ],
    [
      -0.035947,
      0.011323,
      0.000861
    ],
    [
      0.057229,
      0.012044,
      0.000859
    ],
    [
      -0.035046,
      0.012747,
      0.000856
    ],
    [
      0.057315,
      0.013728,
      0.000853
    ],
    [
      -0.034033,
      0.014093,
      0.000852
    ],
    [
      0.057059,
      0.015393,
      0.000847
    ],
    [
      -0.032916,
      0.015354,
      0.000847
    ],
    [
      -0.031707,
      0.016526,
      0.000843
    ],
    [
      0.056494,
      0.01698,
      0.000842
    ],
    [
      -0.030417,
      0.017609,
      0.000839
    ],
    [
      0.055657,
      0.018442,
      0.000837
    ],
    [
      -0.029057,
      0.018603,
      0.000836
    ],
    [
      0.054576,
      0.019734,
      0.000832
    ],
    [
      -0.027625,
      0.019488,
      0.000833
    ],
    [
      -0.007987,
      0.019217,
      0.000834
    ],
    [
      -0.009674,
      0.019264,
      0.000834
    ],
    [
      -0.006376,
      0.019331,
      0.000833
    ],
    [
      -0.011719,
      0.019465,
      0.000833
    ],
    [
      -0.026074,
      0.020107,
      0.000831
    ],
    [
      -0.003547,
      0.019968,
      0.000831
    ],
    [
      -0.017317,
      0.020283,
      0.000831
    ],
    [
      0.053304,
      0.020841,
      0.000828
    ],
    [
      -0.001682,
      0.020492,
      0.00083
    ],
    [
      -0.024458,
      0.020462,
      0.00083
    ],
    [
      -0.01955,
      0.020523,
      0.000829
    ],
    [
      -0.022822,
      0.020614,
      0.000829
    ],
    [
      -0.021185,
      0.020621,
      0.000829
    ],
    [
      0.051897,
      0.02177,
      0.000825
    ],
    [
      0.00252,
      0.02183,
      0.000825
    ],
    [
      0.050396,
      0.022541,
      0.000822
    ],
    [
      0.004289,
      0.022339,
      0.000823
    ],
    [
      0.005893,
      0.022725,
      0.000822
    ],
    [
      0.048833,
      0.023175,
      0.00082
    ],
    [
      0.007532,
      0.023013,
      0.00082
    ],
    [
      0.047229,
      0.023697,
      0.000818
    ],
    [
      0.018819,
      0.024292,
      0.000816
    ],
    [
      0.045597,
      0.024124,
      0.000817
    ],
    [
      0.043946,
      0.02447,
      0.000816
    ],
    [
      0.042281,
      0.024744,
      0.000815
    ],
    [
      0.024481,
      0.024838,
      0.000815
    ],
    [
      0.039831,
      0.025039,
      0.000816
    ],
    [
      0.029883,
      0.025204,
      0.000813
    ],
    [
      0.03646,
      0.025249,
      0.000813
    ],
    [
      0.03312,
      0.02529,
      0.000813
    ]
  ],
  cells: [
    [
      0,
      1,
      2
    ],
    [
      0,
      3,
      1
    ],
    [
      4,
      3,
      0
    ],
    [
      4,
      5,
      3
    ],
    [
      6,
      5,
      4
    ],
    [
      6,
      7,
      5
    ],
    [
      8,
      7,
      6
    ],
    [
      8,
      9,
      7
    ],
    [
      8,
      10,
      9
    ],
    [
      11,
      10,
      8
    ],
    [
      11,
      12,
      10
    ],
    [
      13,
      12,
      11
    ],
    [
      13,
      14,
      12
    ],
    [
      15,
      14,
      13
    ],
    [
      15,
      16,
      14
    ],
    [
      17,
      16,
      15
    ],
    [
      17,
      18,
      16
    ],
    [
      19,
      18,
      17
    ],
    [
      19,
      20,
      18
    ],
    [
      21,
      20,
      19
    ],
    [
      22,
      20,
      21
    ],
    [
      22,
      23,
      20
    ],
    [
      24,
      23,
      22
    ],
    [
      24,
      25,
      23
    ],
    [
      26,
      25,
      24
    ],
    [
      26,
      27,
      25
    ],
    [
      28,
      27,
      26
    ],
    [
      28,
      29,
      27
    ],
    [
      30,
      29,
      28
    ],
    [
      31,
      29,
      30
    ],
    [
      31,
      32,
      29
    ],
    [
      33,
      32,
      31
    ],
    [
      34,
      32,
      33
    ],
    [
      35,
      32,
      34
    ],
    [
      35,
      36,
      32
    ],
    [
      35,
      37,
      36
    ],
    [
      38,
      37,
      35
    ],
    [
      39,
      37,
      38
    ],
    [
      39,
      40,
      37
    ],
    [
      41,
      40,
      39
    ],
    [
      41,
      42,
      40
    ],
    [
      43,
      42,
      41
    ],
    [
      43,
      44,
      42
    ],
    [
      45,
      44,
      43
    ],
    [
      45,
      46,
      44
    ],
    [
      47,
      46,
      45
    ],
    [
      48,
      46,
      47
    ],
    [
      48,
      49,
      46
    ],
    [
      50,
      49,
      48
    ],
    [
      50,
      51,
      49
    ],
    [
      52,
      51,
      50
    ],
    [
      52,
      53,
      51
    ],
    [
      52,
      54,
      53
    ],
    [
      55,
      54,
      52
    ],
    [
      55,
      56,
      54
    ],
    [
      57,
      56,
      55
    ],
    [
      57,
      58,
      56
    ],
    [
      59,
      58,
      57
    ],
    [
      59,
      60,
      58
    ],
    [
      61,
      60,
      59
    ],
    [
      61,
      62,
      60
    ],
    [
      63,
      62,
      61
    ],
    [
      63,
      64,
      62
    ],
    [
      65,
      64,
      63
    ],
    [
      65,
      66,
      64
    ],
    [
      67,
      66,
      65
    ],
    [
      67,
      68,
      66
    ],
    [
      69,
      68,
      67
    ],
    [
      70,
      68,
      69
    ],
    [
      70,
      71,
      68
    ],
    [
      72,
      71,
      70
    ],
    [
      72,
      73,
      71
    ],
    [
      74,
      73,
      72
    ],
    [
      74,
      75,
      73
    ],
    [
      76,
      77,
      74
    ],
    [
      77,
      75,
      74
    ],
    [
      76,
      78,
      77
    ],
    [
      79,
      75,
      77
    ],
    [
      76,
      80,
      78
    ],
    [
      81,
      80,
      76
    ],
    [
      82,
      75,
      79
    ],
    [
      81,
      83,
      80
    ],
    [
      82,
      84,
      75
    ],
    [
      85,
      84,
      82
    ],
    [
      86,
      83,
      81
    ],
    [
      86,
      87,
      83
    ],
    [
      88,
      87,
      86
    ],
    [
      88,
      89,
      87
    ],
    [
      85,
      90,
      84
    ],
    [
      91,
      90,
      85
    ],
    [
      91,
      92,
      90
    ],
    [
      93,
      92,
      91
    ],
    [
      94,
      92,
      93
    ],
    [
      94,
      95,
      92
    ],
    [
      96,
      95,
      94
    ],
    [
      96,
      97,
      95
    ],
    [
      98,
      97,
      96
    ],
    [
      98,
      99,
      97
    ],
    [
      98,
      100,
      99
    ],
    [
      98,
      101,
      100
    ],
    [
      102,
      101,
      98
    ],
    [
      102,
      103,
      101
    ],
    [
      104,
      103,
      102
    ],
    [
      104,
      105,
      103
    ],
    [
      106,
      105,
      104
    ]
  ]
};
