import React, { PureComponent } from 'react';
import Head from 'next/head';
import classNames from 'classnames';

import ContactButtonModal, { ContactInfoProps } from '../../components/ContactButtonModal/ContactButtonModal';
import Footer, { FooterProps } from '../../components/Footer/Footer';
import Header, { HeaderProps } from '../../components/Header/Header';
import WagtailUserbar from '../../components/WagtailUserbar';

import s from './BasePage.module.scss';

import data from './BasePage.data';

interface SeoProps {
    seoHtmlTitle: string,
    seoMetaDescription: string,
    seoOgTitle: string,
    seoOgDescription: string,
    seoOgUrl: string,
    seoOgImage: string,
    seoOgType: string,
    seoTwitterTitle: string,
    seoTwitterDescription: string,
    seoTwitterUrl: string,
    seoTwitterImage: string,
    seoMetaRobots: string,
    canonicalLink: string,
}

interface BasePageProps {
    children: any;
    mainMenu: HeaderProps;
    siteSetting: any;
    seo: SeoProps;
    id: string;
    componentName: string;
    preloadImages: Array<string>;
    wagtailUserbar: object;
}

export default class BasePage extends PureComponent<BasePageProps> {
    state = {};

    static defaultProps = {
        siteSetting: data.siteSetting,
        preloadImages: []
    };

    render() {
        const {
            children,
            mainMenu,
            siteSetting,
            id,
            seo,
            componentName,
            wagtailUserbar,
            preloadImages,
        } = this.props;

        const {
            seoHtmlTitle,
            seoMetaDescription,
            seoOgTitle,
            seoOgDescription,
            seoOgUrl,
            seoOgImage,
            seoOgType,
            seoTwitterTitle,
            seoTwitterDescription,
            seoTwitterUrl,
            seoTwitterImage,
            seoMetaRobots,
            canonicalLink,
        } = seo;

        const { footer, contactModal }: { footer: FooterProps, contactModal: ContactInfoProps }
            = siteSetting;

        const lightLogo = componentName === 'PageCaseEnhanced';

        const lightMobileMenu = componentName === 'StartPage'
            || componentName === 'OffersPage'
            || componentName === 'PageArticle'
            || componentName === 'PageCaseEnhanced';

        const menuColor = componentName === 'PageCaseEnhanced'
            ? 'white'
            : componentName === 'StartPage'
                || componentName === 'CaseListPage'
                || componentName === 'OffersPage'
                || componentName === 'PageArticle'
                || componentName === 'PageArchive'
                    ? 'purple'
                    : 'black';

        const showLogoBlob = ['CaseListPage', 'PageArchive'].indexOf(componentName) > -1;

        const showMenuBlob = componentName === 'StartPage'
            || componentName === 'OffersPage'
            || componentName === 'PageArticle';

        return (
            <>
                <Head>
                    <title>{seoHtmlTitle}</title>
                    {preloadImages && preloadImages.map(img =>
                        <link rel="preload" as="image" href={img} />
                    )}
                    <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/favicons/apple-touch-icon-57x57.png" />
                    <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/favicons/apple-touch-icon-114x114.png" />
                    <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/favicons/apple-touch-icon-72x72.png" />
                    <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/favicons/apple-touch-icon-144x144.png" />
                    <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/favicons/apple-touch-icon-60x60.png" />
                    <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/favicons/apple-touch-icon-120x120.png" />
                    <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/favicons/apple-touch-icon-76x76.png" />
                    <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/favicons/apple-touch-icon-152x152.png" />
                    <link rel="icon" type="image/png" href="/favicons/favicon-196x196.png" sizes="196x196" />
                    <link rel="icon" type="image/png" href="/favicons/favicon-96x96.png" sizes="96x96" />
                    <link rel="icon" type="image/png" href="/favicons/favicon-32x32.png" sizes="32x32" />
                    <link rel="icon" type="image/png" href="/favicons/favicon-16x16.png" sizes="16x16" />
                    <link rel="icon" type="image/png" href="/favicons/favicon-128.png" sizes="128x128" />
                    <meta name="application-name" content="&nbsp;"/>
                    <meta name="msapplication-TileColor" content="#FFFFFF" />
                    <meta name="msapplication-TileImage" content="mstile-144x144.png" />
                    <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
                    <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
                    <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
                    <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />

                    {!!seoMetaDescription && (
                        <meta name="description" content={seoMetaDescription} />
                    )}
                    {!!seoOgTitle && (
                        <meta property="og:title" content={seoOgTitle} />
                    )}
                    {!!seoOgDescription && (
                        <meta
                            property="og:description"
                            content={seoOgDescription}
                        />
                    )}
                    {!!seoOgUrl && (
                        <meta property="og:url" content={seoOgUrl} />
                    )}
                    {!!seoOgImage && (
                        <meta property="og:image" content={seoOgImage} />
                    )}
                    {!!seoOgType && (
                        <meta property="og:type" content={seoOgType} />
                    )}
                    {!!seoTwitterTitle && (
                        <meta
                            property="twitter:title"
                            content={seoTwitterTitle}
                        />
                    )}
                    {!!seoTwitterDescription && (
                        <meta
                            property="twitter:description"
                            content={seoTwitterDescription}
                        />
                    )}
                    {!!seoTwitterUrl && (
                        <meta property="twitter:url" content={seoTwitterUrl} />
                    )}
                    {!!seoTwitterImage && (
                        <meta
                            property="twitter:image"
                            content={seoTwitterImage}
                        />
                    )}
                    {!!canonicalLink && (
                        <link rel="canonical" href={canonicalLink} />
                    )}
                </Head>
                <div className={s.BasePage}>
                    <Header
                        {...mainMenu}
                        activeId={id}
                        menuColor={menuColor}
                        lightMobileMenu={lightMobileMenu}
                        lightLogo={lightLogo}
                        showMenuBlob={showMenuBlob}
                        showLogoBlob={showLogoBlob}
                        componentName={componentName}
                    />

                    <main>
                        {children}

                        <Footer {...footer} siteSetting={siteSetting} />
                        <ContactButtonModal {...contactModal} />
                    </main>
                </div>
                {!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
            </>
        );
    }
}
