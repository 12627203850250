const mesh = {
    positions: [
        [-0.171744, 0, -1.254399],
        [0.171744, 0, -1.254399],
        [0, 0, -1.265963],
        [0.336489, 0, -1.220716],
        [-0.336489, 0, -1.220716],
        [0.492722, 0, -1.166426],
        [-0.492722, 0, -1.166426],
        [0.63893, 0, -1.093042],
        [-0.63893, 0, -1.093042],
        [-0.773602, 0, -1.002074],
        [0.773602, 0, -1.002074],
        [-0.895225, 0, -0.895036],
        [0.895225, 0, -0.895036],
        [-1.002286, 0, -0.773439],
        [1.002286, 0, -0.773439],
        [-1.093272, 0, -0.638795],
        [1.093272, 0, -0.638795],
        [-1.166673, 0, -0.492618],
        [1.166673, 0, -0.492618],
        [-1.220974, 0, -0.336418],
        [1.220974, 0, -0.336418],
        [-1.254664, 0, -0.171708],
        [1.254664, 0, -0.171708],
        [-1.26623, 0, 0],
        [1.26623, 0, 0],
        [-1.254664, 0, 0.171708],
        [1.254664, 0, 0.171708],
        [-1.220974, 0, 0.336418],
        [1.220974, 0, 0.336418],
        [-1.166673, 0, 0.492618],
        [1.166673, 0, 0.492618],
        [-1.093272, 0, 0.638795],
        [1.093272, 0, 0.638795],
        [-1.002286, 0, 0.773439],
        [1.002286, 0, 0.773439],
        [-0.895225, 0, 0.895036],
        [0.895225, 0, 0.895036],
        [-0.773602, 0, 1.002074],
        [0.773602, 0, 1.002074],
        [-0.63893, 0, 1.093042],
        [0.63893, 0, 1.093042],
        [-0.492722, 0, 1.166426],
        [0.492722, 0, 1.166426],
        [0.336489, 0, 1.220716],
        [-0.336489, 0, 1.220716],
        [0.171744, 0, 1.254399],
        [-0.171744, 0, 1.254399],
        [0, 0, 1.265963],
    ],
    cells: [
        [0, 1, 2],
        [0, 3, 1],
        [4, 3, 0],
        [4, 5, 3],
        [6, 5, 4],
        [6, 7, 5],
        [8, 7, 6],
        [9, 7, 8],
        [9, 10, 7],
        [11, 10, 9],
        [11, 12, 10],
        [13, 12, 11],
        [13, 14, 12],
        [15, 14, 13],
        [15, 16, 14],
        [17, 16, 15],
        [17, 18, 16],
        [19, 18, 17],
        [19, 20, 18],
        [21, 20, 19],
        [21, 22, 20],
        [23, 22, 21],
        [23, 24, 22],
        [25, 24, 23],
        [25, 26, 24],
        [27, 26, 25],
        [27, 28, 26],
        [29, 28, 27],
        [29, 30, 28],
        [31, 30, 29],
        [31, 32, 30],
        [33, 32, 31],
        [33, 34, 32],
        [35, 34, 33],
        [35, 36, 34],
        [37, 36, 35],
        [37, 38, 36],
        [39, 38, 37],
        [39, 40, 38],
        [41, 40, 39],
        [41, 42, 40],
        [41, 43, 42],
        [44, 43, 41],
        [44, 45, 43],
        [46, 45, 44],
        [46, 47, 45],
    ],
};

export default mesh;
