import * as React from 'react';
import classNames from 'classnames';

import Link from '../Link';
import MenuBlobGroup from '../MenuBlobGroup';

import s from './MenuMobile.module.scss';

export interface MenuMobileProps {
    activeId: string;
    items: any[];
    light: boolean;
}

const MenuMobile: React.FC<MenuMobileProps> = ({ activeId, items, light }) => {
    const [isActive, setIsActive] = React.useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const classes = classNames(s.Root, { [s.RootActive]: isActive });

    return (
        <div className={classes}>
            <ButtonMenu light={light} onClick={toggleMenu} />
            {items && (
                <nav className={s.Content}>
                    <ul className={s.List}>
                        {items.map((item, index) => {
                            let classes = classNames(s.Item, {
                                [s.ItemCurrent]: item.linkPageId === activeId,
                            });

                            return (
                                <li key={index} className={classes}>
                                    <Link className={s.Link} href={item.url}>
                                        {item.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            )}
        </div>
    );
};

const ButtonMenu = ({ onClick, light }) => {
    const classes = classNames(s.Button, { [s.ButtonLight]: light });

    return (
        <button onClick={onClick} className={classes}>
            <div className={s.ButtonContainer}>
                <span className={s.ButtonIcon}>
                    <span className={s.ButtonDash}></span>
                    <span className={s.ButtonDash}></span>
                    <span className={s.ButtonDash}></span>
                </span>
            </div>
        </button>
    );
};

export default MenuMobile;
